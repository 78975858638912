import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IMessage, ITextMessage } from '../types/message';
import { noop } from 'lodash';
import { getMessageDelay as messageDelay } from '../utils/chatbotUtils';
import configuration from 'configuration';

interface IChatbotContext {
  messages: IMessage[];
  send(text: string, sender: string, received?: boolean, data?: any): void;
  replying: boolean;
  setReplying(replying: boolean): void;
  remove(snapshotsnapshotVersion: string): void;
  getMessageDelay(message: string): number;
}

export const ChatbotContext = createContext<IChatbotContext>({
  messages: [],
  send: noop,
  replying: false,
  setReplying: noop,
  remove: noop,
  getMessageDelay: () => 1000,
});

export const useChatbot = () => useContext(ChatbotContext);

function ChatbotProvider({ children }: PropsWithChildren<unknown>) {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [replying, setReplying] = useState(false);

  const send = (
    text: string,
    sender: string,
    received: boolean = true,
    data: any = {}
  ) => {
    const message: ITextMessage = {
      text,
      sender,
      type: 'text',
      received,
      data,
      date: new Date(),
    };

    setMessages((current) => [...current, message]);
  };

  function remove(snapshotVersion: string) {
    const index = messages.findIndex(
      (i) => i.data?.snapshotVersion === snapshotVersion
    );

    const v = [...messages];

    v.splice(index - 1);

    setMessages(v);
  }

  const getMessageDelay = useMemo(() => {
    return configuration.enableChatDelay ? messageDelay : () => 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChatbotContext.Provider
      value={{ messages, send, remove, replying, setReplying, getMessageDelay }}
    >
      {children}
    </ChatbotContext.Provider>
  );
}

export default ChatbotProvider;
