import React from 'react';
import configuration from 'configuration';
import MaintenancePage from './MaintenancePage';

import './MaintenancePage.scss';

function MaintenanceBoundary({ children }: React.PropsWithChildren<unknown>) {
  if (configuration.maintenanceEnabled) {
    return <MaintenancePage />;
  }

  return <>{children}</>;
}

export default MaintenanceBoundary;
