import { useState, useMemo } from 'react';
import { DatePicker, DatePickerView } from '@material-ui/pickers';
import { formatDate, isValid } from 'shared/utils/dateUtils';
import { TextField, TextFieldProps } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import configuration from 'configuration';
import {
  addMinutes,
  isToday,
  isBefore,
  startOfYesterday,
  addHours,
  max,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { usePreQuote } from 'providers/QuoteProvider';
import { useLocale } from 'providers/LocaleProvider';
import * as Sentry from '@sentry/react';
import { ApplicationError, ErrorCodes } from 'constants/errors';

interface OwnProps {
  handleChange: (name: string, value: string) => void;
  name: string;
  min?: ParsableDate;
  max?: ParsableDate;
  openTo?: DatePickerView;
}

type Props = OwnProps & TextFieldProps;

function DateInput({ handleChange, name, openTo, error }: Props) {
  const { lang: locale } = useLocale();
  const [date, setDate] = useState<Date | null>(null);

  const {
    productHistory: { current: product },
  } = usePreQuote();

  const { timezone, settings } = product;

  function getFormattedValue(date: Date) {
    return formatDate(date, 'EEEE do MMMM yyyy', locale);
  }

  function onSend(value: Date | null) {
    setDate(value);

    if (!isValid(value)) {
      return;
    }

    try {
      const formatted = getValue(value, timezone);

      handleChange(name, formatted);
    } catch (error) {
      Sentry.captureException(
        new ApplicationError(
          'DateInput Error',
          ErrorCodes.DATE_INPUT_ERROR,
          'DateInput Exception: ' + String(error)
        )
      );
    }
  }

  const { min, max } = useMemo(
    () =>
      getDateRange(
        settings.maxStartLeadTimeHours,
        configuration.quoteStartDateLeadTime,
        timezone
      ),
    [settings.maxStartLeadTimeHours, timezone]
  );

  return (
    <div className="FormDateInput">
      <DatePicker
        id={name}
        name={name}
        views={['year', 'month', 'date']}
        openTo={openTo}
        className="FormDateInput-Picker"
        data-testid="date-input"
        format="yyyy-MM-dd"
        minDate={min}
        maxDate={max}
        value={date}
        onChange={onSend}
        inputVariant="outlined"
        TextFieldComponent={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            value={(isValid(date) && getFormattedValue(date)) || ''}
            error={error}
          />
        )}
      />
    </div>
  );
}

function getDateRange(
  maxStartLeadTimeHours: number,
  quoteStartDateLeadTime: number,
  timeZone: string
): { min?: Date; max?: Date } {
  // startDate types should have a max date of the product config value

  const now = utcToZonedTime(new Date(), timeZone);

  return {
    min: addMinutes(now, quoteStartDateLeadTime),
    max: addHours(now, maxStartLeadTimeHours),
  };
}

function getValue(value: Date, timeZone: string) {
  const { quoteStartDateLeadTime } = configuration;

  const zoned = utcToZonedTime(value, timeZone);

  if (isBefore(value, startOfYesterday())) {
    return zoned.toISOString();
  }

  // Add some time so that it's not in the past by the time it reaches the server
  const minDate = addMinutes(new Date(), quoteStartDateLeadTime);

  // If is today, return the minDate
  if (isToday(value)) {
    return minDate.toISOString();
  }

  const formatted = formatDate(value, 'yyyy-MM-dd');

  // Parse the date into the timezone (getting midnight in that timezone)
  const parsed = zonedTimeToUtc(formatted, timeZone);

  // Return the parsed date, unless it's before the minDate
  const date = max([parsed, minDate]);

  // Return as ISO
  return date.toISOString();
}

export default DateInput;
