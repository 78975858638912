import React from 'react';
import { NewIcon } from 'components/Icons';
import { useLocale } from 'providers/LocaleProvider';
import { useProduct, useStrings } from 'hooks';
import String from 'components/String/String';
import EventManager from 'utils/EventManager';

import './InstalmentsBanner.scss';
import configuration from 'configuration';

interface Props {
  style?: React.CSSProperties;
  visible?: boolean;
  isRenewal: boolean;
  productId: string;
}

const InstalmentsBanner = ({
  style,
  visible = true,
  isRenewal,
  productId,
}: Props) => {
  const { lang: locale } = useLocale();
  const { get } = useStrings();
  const {
    productHistory: { current: product },
  } = useProduct(productId, locale);

  const showInstalmentsBanner =
    product &&
    configuration.instalmentsBannerProducts.includes(product.code) &&
    visible;

  function onClickPhone() {
    if (!product) {
      return;
    }

    EventManager.track({
      event: 'InstallmentsCall',
      productId: product.id,
      label: isRenewal ? 'Renewal' : 'New Business',
    });
  }

  return showInstalmentsBanner ? (
    <div
      className="InstalmentsBanner"
      style={style}
      data-testid="InstalmentsBanner"
    >
      <span className="InstalmentsBanner__star">
        <NewIcon
          text={<String id="pages.quote.installments_banner.contact.icon" />}
        />
      </span>
      <div className="InstalmentsBanner__title">
        <String id="pages.quote.installments_banner.contact.title" />
      </div>
      <div className="InstalmentsBanner__content">
        <String id="pages.quote.installments_banner.contact.content" />
      </div>
      <div className="InstalmentsBanner__footer">
        <String id="pages.quote.installments_banner.contact.call_us" />
        <br />
        <a
          href={`tel:${get(
            'pages.quote.installments_banner.contact.telephone_number'
          )}`}
          onClick={onClickPhone}
        >
          <String id="pages.quote.installments_banner.contact.telephone_number" />
        </a>{' '}
        <String id="pages.quote.installments_banner.contact.telephone_number_text" />{' '}
        <String id="pages.quote.installments_banner.contact.bank_card_ready" />
        <br />
        <span className="InstalmentsBanner__footer--small">
          <String id="pages.quote.installments_banner.contact.opening_hours" />
        </span>
      </div>
    </div>
  ) : null;
};

export default InstalmentsBanner;
