import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { noop } from 'lodash';
import configuration from 'configuration';
import { Auth0Client, IdToken, User } from '@auth0/auth0-spa-js';
import LocalStorage from 'utils/LocalStorage';
import Logger from 'utils/Logger';
import EventManager from '../utils/EventManager';
import { MockAuthClaims, MockAuthUser } from '../tests/mock/auth.user';
import { loginStategies } from 'utils/login/LoginStrategy';
import AnonymousAuthClient from '../AnonymousAuthClient';

const logger = Logger.create('auth');

export interface IAuthContext {
  loading: boolean;
  isAuthenticated?: boolean;
  user: User | undefined;
  client?: Auth0Client;
  anonClient?: AnonymousAuthClient;
  login(
    locale: string,
    productId: string,
    productCode?: string,
    redirectUri?: string,
    connections?: string[],
    country?: string
  ): void;
  logout(): void;
  handleCallback(): Promise<{ redirectUri: string; productId: string }>;
  init(): Promise<void>;
  claims?: IdToken;
  generateAnonymousToken: any;
  accessToken?: string;
  setTokenAsCookie: any;
  hasLoginWithPartner: any;
  handleLoginWithPartner: any;
}

export const AuthContext = createContext<IAuthContext>({
  loading: true,
  isAuthenticated: false,
  client: undefined,
  anonClient: undefined,
  logout: noop,
  login: noop,
  init: noop as any,
  handleCallback: noop as any,
  user: undefined as any,
  generateAnonymousToken: noop as any,
  setTokenAsCookie: noop as any,
  hasLoginWithPartner: noop as any,
  handleLoginWithPartner: noop as any,
});

export const useAuth = () => useContext(AuthContext);

interface Props {
  client?: Auth0Client;
  anonClient?: AnonymousAuthClient;
  mocked?: boolean;
}

function AuthProvider({
  client,
  anonClient,
  mocked,
  children,
}: PropsWithChildren<Props>) {
  const [loading, setLoading] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>();
  const [claims, setClaims] = useState<IdToken | undefined>();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [user, setUser] = useState<User | undefined>();

  const login = (
    locale: string,
    productId: string,
    productCode?: string,
    redirectUri?: string,
    connections?: string[],
    country?: string
  ) => {
    const validConnections = connections?.filter(Boolean) ?? [];

    logger.info('login()', { redirectUri: redirectUri, validConnections });

    LocalStorage.set('locale', locale);
    const [connection] = validConnections;

    const appState = { redirectUri: redirectUri, productId: productId };

    const productLoginStrategy = loginStategies[productCode!];

    // Product specific login strategy
    if (productLoginStrategy) {
      return productLoginStrategy(
        appState,
        productCode,
        client,
        connections,
        locale,
        country
      );
    } else {
      // usual default flow
      if (validConnections.length === 1 && connection !== 'email') {
        // This will redirect the app to the auth0 domain and attempt to restore an existing session for
        // the given connection. If a session isn't found then auth0 redirects to our Universal Login page.
        // You cannot provide connection:'email' here unless you know an email session already exists
        // or else auth0 will return an error.
        return client?.loginWithRedirect({
          appState: appState,
          connection,
          language: locale,
          productCode,
          country,
        });
      } else {
        // For multiple connections and email we force auth0 to bypass the session restore mechanism
        // and skip directly to our Universal Login page (using prompt:'login'). The `connections` property
        // is not understood by auth0 and is simply passed on to our Universal Login page where we deal with it.
        return client?.loginWithRedirect({
          appState: appState,
          prompt: 'login',
          connections: validConnections,
          language: locale,
          productCode,
          country,
        });
      }
    }
  };

  const logout = () => {
    logger.info('logout()', { returnTo: configuration.auth0.logoutUri });
    return client?.logout({ returnTo: configuration.auth0.logoutUri });
  };

  const handleCallback = async () => {
    logger.info('handleCallback() started');
    setLoading(true);

    const response = await client?.handleRedirectCallback();
    const user = await client?.getUser();
    const claims = await client?.getIdTokenClaims();
    const token = await getAccessToken();

    if (user?.sub) {
      EventManager.set({ user_id: user.sub });
    }

    setUser(user);
    setClaims(claims);
    setAccessToken(token);
    setIsAuthenticated(true);
    setLoading(false);

    const appState = {
      redirectUri: '/',
      ...response?.appState,
    };

    logger.info('handleCallback()', {
      authenticated: true,
      user,
      claims,
      appState,
    });

    return appState;
  };

  const setTokenAsCookie = async () => {
    const token = await getAccessToken();

    if (!token) {
      throw new Error('user is not authenticated');
    }

    const d = new Date();
    d.setTime(d.getTime() + 600000);

    let cookie = `authorization=${token}`;
    cookie += `;expires=${d.toUTCString()}`;
    cookie += `;Domain=${configuration.api.domain}`;
    cookie += ';path=/';
    cookie += ';SameSite=None;secure';

    document.cookie = cookie;
    return true;
  };

  const hasLoginWithPartner = (uri: string) => {
    return uri.includes(configuration.api.loginPartnerPath);
  };

  const handleLoginWithPartner = (uri: string) => {
    try {
      setTokenAsCookie();

      const redirectUri = new URLSearchParams(
        uri?.substring(uri.indexOf('?'))
      ).get('redirectUri');

      const uberServiceBase =
        configuration.api.base + configuration.api.uberServicePath;

      const url = new URL(uberServiceBase);
      url.searchParams.set(
        'redirectUri',
        `${window.location.origin}/${configuration.region}${redirectUri}` || ''
        // We add the '/${configuration.region'
        // here because the region is removed on the
        // src/utils/pageUtils.ts:L20
      );

      window.location.replace(url.href);
    } catch (e) {
      console.error(e);
    }
  };

  const generateAnonymousToken = async () => {
    if (!accessToken) {
      return anonClient!.generateAnonymousToken();
    }
  };

  const init = async () => {
    logger.info('init() started');

    if (mocked) {
      logger.debug('Authentication is off!');
      setUser(MockAuthUser);
      setClaims(MockAuthClaims);
      setAccessToken('some-token');
      setIsAuthenticated(true);
      setLoading(false);
      return;
    }

    const params = new URL(window.location.href).searchParams;
    // Intentionally setting the authenticated boolean to false here, if the user has come
    // in via the flex-api service auth route (auth happens outside customer-web in this flow via a flex-api/login service)
    // This is because client?.isAuthenticated() cannot be trusted for certain use-cases. This sdk
    // function will return true for any valid access token stored regardless of connection identity (e.g. if
    // user signed into UKC (email/password), and then UKO (passwordless), client?.isAuthenticated() will return true, resulting
    // in the getProduct call failing if the product requested has a different identity to the access token
    // sent - see ApolloProvider, which redirects to /login if this happens). While that behaviour hasn't
    // necessarily been an issue before - for UKA, it results in the 'flexOnboardComplete' param
    // being stripped prematurely, which then causes an infinite redirect loop (see LoginStrategy).
    // A long-term solution might involve checking the decoded token identities against the
    // identities allowed for the requested product (we fail-fast on the FE)
    const authenticated = params.get('flexOnboardComplete')
      ? false
      : await client?.isAuthenticated();

    const user = await client?.getUser();
    if (user?.sub) {
      EventManager.set({ user_id: user.sub });
    }
    const claims = await client?.getIdTokenClaims();
    const token = await getAccessToken();

    logger.info('init()', { authenticated: authenticated, user, claims });

    setIsAuthenticated(authenticated);
    setUser(user);
    setClaims(claims);
    setAccessToken(token);
  };

  async function getAccessToken() {
    const authenticated = await client?.isAuthenticated();

    if (!authenticated) {
      return undefined;
    }

    return client?.getTokenSilently();
  }

  return (
    <AuthContext.Provider
      value={{
        init,
        client,
        anonClient,
        loading,
        isAuthenticated,
        user,
        claims,
        logout,
        login,
        handleCallback,
        accessToken,
        generateAnonymousToken,
        setTokenAsCookie,
        hasLoginWithPartner,
        handleLoginWithPartner,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
