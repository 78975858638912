import { useMemo, useState } from 'react';
import {
  addHours,
  addMinutes,
  formatISO,
  isToday,
  max,
  startOfToday,
  isBefore,
  setYear,
  getYear,
} from 'date-fns';
import { DatePicker } from '@material-ui/pickers';
import { get as lodashGet } from 'lodash';

import { DateQuestion, DateQuestionTemplateEnum } from 'shared/types/question';
import { ILocale, IProductDetailsFragment } from 'shared/graphql/api/types';
import {
  convertToTimeZone,
  parseFromTimeZone,
  formatDate,
  isValid,
  adjustDateForTimezone,
} from 'shared/utils/dateUtils';
import configuration, { IConfiguration } from 'configuration';
import { useStrings } from 'hooks';

import { AnswerQuestion, usePreQuote } from 'providers/QuoteProvider';
import { useLocale } from 'providers/LocaleProvider';
import SendButton from '../../../Buttons/Send/SendButton';
import * as Sentry from '@sentry/react';
import { ApplicationError, ErrorCodes } from 'constants/errors';

import './DateInput.scss';

interface Props {
  question: DateQuestion;
  onAnswer: AnswerQuestion;
}

function DateInput({ question, onAnswer }: Readonly<Props>) {
  const { lang: locale } = useLocale();
  const { get } = useStrings();
  const { template, answer_path } = question;
  const {
    $,
    productHistory: { current: product },
  } = usePreQuote();

  const [date, setDate] = useState<Date | null>(() => {
    if (!answer_path) {
      return null;
    }
    const currentValue = lodashGet({ $ }, answer_path);

    return currentValue ? new Date(currentValue) : null;
  });

  const { timezone } = product;

  function onSend() {
    if (!isValid(date)) {
      return;
    }

    try {
      const presentedValue = getPresentedValue(date, template, locale);
      const submittedValue = getSubmittedValue(date, template, timezone);
      onAnswer(presentedValue, submittedValue);
    } catch (error) {
      Sentry.captureException(
        new ApplicationError(
          'DateInput Error',
          ErrorCodes.DATE_INPUT_ERROR,
          'DateInput Exception: ' + String(error)
        )
      );
    }
  }

  const { min, max } = useMemo(
    () => getDateRange(question, product, configuration),
    [question, product]
  );

  return (
    <div className="DateInput">
      <DatePicker
        disableFuture={
          template === DateQuestionTemplateEnum.dateOfBirth ||
          template === DateQuestionTemplateEnum.yearOnly
        }
        disablePast={template === DateQuestionTemplateEnum.futureDate}
        views={
          template === DateQuestionTemplateEnum.yearOnly
            ? ['year']
            : ['year', 'month', 'date']
        }
        openTo={
          template === DateQuestionTemplateEnum.dateOfBirth ||
          template === DateQuestionTemplateEnum.yearOnly
            ? 'year'
            : undefined
        }
        className={'DateInput-Picker'}
        data-testid="date-input"
        format="yyyy-MM-dd"
        minDate={min}
        maxDate={max}
        value={date}
        onChange={setDate}
        okLabel={get('pages.quote.date.ok')}
        cancelLabel={get('pages.quote.date.cancel')}
        TextFieldComponent={({ onClick }) => (
          <input
            autoFocus
            readOnly
            value={
              (isValid(date) && getPresentedValue(date, template, locale)) || ''
            }
            onClick={onClick}
            placeholder={get('pages.quote.chatbot.placeholder')}
            type="string"
            aria-label="date-input"
            data-testid="date-input"
            className={`DateInput-input${isValid(date) ? '-selected' : ''}`}
          />
        )}
      />
      <SendButton
        onClick={onSend}
        disabled={!isValid(date)}
        dataTestId="date-input-button"
      />
    </div>
  );
}

export function getPresentedValue(
  date: Date,
  template: DateQuestionTemplateEnum,
  locale: ILocale
) {
  if (template === DateQuestionTemplateEnum.yearOnly) {
    return formatDate(date, 'yyyy', locale);
  }
  return formatDate(date, 'EEEE do MMMM yyyy', locale);
}

export function getSubmittedValue(
  value: Date,
  template: DateQuestionTemplateEnum,
  timeZone: string
): string {
  const zoned = convertToTimeZone(value, timeZone);

  if (template === DateQuestionTemplateEnum.dateTime) {
    return zoned.toISOString();
  }

  if (template === DateQuestionTemplateEnum.yearOnly) {
    const date = setYear(startOfToday(), getYear(value));
    return formatISO(date, { representation: 'date' });
  }

  if (template === DateQuestionTemplateEnum.startDate) {
    const { quoteStartDateLeadTime } = configuration;

    // Add some time so that it's not in the past by the time it reaches the server
    const minDate = addMinutes(new Date(), quoteStartDateLeadTime);

    // If is today, return the minDate
    if (isToday(value)) {
      return minDate.toISOString();
    }

    // Parse the date into the timezone (getting midnight in that timezone)
    const parsed: Date = parseFromTimeZone(value, timeZone);
    parsed.setHours(0, 0, 0, 0);

    // Return the parsed date, unless it's before the minDate
    const date = max([parsed, minDate]);

    // Return as ISO
    return date.toISOString();
  }

  return formatISO(zoned, { representation: 'date' });
}

export function getDateRange(
  question: DateQuestion,
  product: IProductDetailsFragment,
  configuration: IConfiguration
): { min?: Date; max?: Date } {
  if (question.template === DateQuestionTemplateEnum.startDate) {
    // maxStartLeadTimeHours can be optionally overridden by the question
    const maxStartLeadTimeHours =
      question.startDateSettings?.maxStartLeadTimeHours ??
      product.settings.maxStartLeadTimeHours;
    const now = convertToTimeZone(new Date(), product.timezone);

    const maxDate = question.startDateSettings?.maxDate;
    const maxLeadTimeDate = addHours(now, maxStartLeadTimeHours);

    let max = maxLeadTimeDate;

    if (maxDate !== undefined) {
      const adjustedDate = adjustDateForTimezone(new Date(maxDate));
      max = isBefore(adjustedDate, maxLeadTimeDate)
        ? adjustedDate
        : maxLeadTimeDate;
    }
    return {
      min: addMinutes(now, configuration.quoteStartDateLeadTime),
      max,
    };
  }

  // The following templates should not display future dates in the picker
  if (
    question.template === DateQuestionTemplateEnum.dateOfBirth ||
    question.template === DateQuestionTemplateEnum.endDate ||
    question.template === DateQuestionTemplateEnum.yearOnly
  ) {
    return {
      max: startOfToday(),
    };
  }

  return {};
}

export default DateInput;
