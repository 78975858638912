import { Logger as InshurLogger, LogLevel } from '@inshur/logger';
import configuration from 'configuration';

const serviceName = 'inshur-customer-web';
const logLevel: LogLevel = configuration.logLevel as LogLevel;

class Logger extends InshurLogger {
  defaultMeta = {
    name: serviceName,
  };
  public static create(name: string) {
    return Logger.new({
      serviceName,
      logLevel,
      defaultMeta: {
        name,
      },
    });
  }
}

export default Logger;
