import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import PageHeader from './PageHeader/PageHeader';
import Banner from 'components/Banner/Banner';
import Menu from 'components/Menu/Menu';
import { useNavigation } from '../../providers/NavigationProvider';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import configuration from 'configuration';
import String from 'components/String/String';

import './Page.scss';

export interface PageProps {
  name: string;
  title?: string;
  description?: string;
  slug?: string;
  center?: boolean;
  padded?: boolean;
  showHeader?: boolean;
  onRestart?(): void;
  showLogout?: boolean;
  full?: boolean;
}

function Page({
  name,
  title,
  slug,
  description,
  children,
  center,
  padded = true,
  showHeader = true,
  onRestart,
  showLogout = true,
  full = false,
}: PropsWithChildren<PageProps>) {
  const { isOpen } = useNavigation();

  const showTestBanner: boolean = configuration.showTestBanner;

  return (
    <div
      className={classnames('Page', name, {
        'Page-center': center,
        'Page-padded': padded,
        'Page-full': full,
      })}
    >
      <Helmet>
        {title && <title>{title}</title>}
        {slug && <title>{slug} - INSHUR</title>}
        {description && <meta name="description" content={description} />}
      </Helmet>

      {showTestBanner && (
        <Banner message={<String id="pages.test_banner.title" />} />
      )}

      {showHeader && <PageHeader />}

      <main className="Page-main" data-testid={name}>
        {isOpen && <Menu onRestart={onRestart} showLogout={showLogout} />}
        <div className="Page-content">
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </main>
    </div>
  );
}

export default Page;
