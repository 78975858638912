import { useAuth } from 'providers/AuthProvider';

export const useUserId = (): string | undefined => {
  const { user } = useAuth();

  if (!user) {
    return undefined;
  }

  return user.sub;
};
