import { Tooltip, IconButton } from '@material-ui/core';
import String from 'components/String/String';
import './HelpText.scss';
import { InfoIcon } from 'components/Icons';

interface Props {
  id: string;
  className?: string;
}

const HelpText = ({ id, className }: Props) => {
  return (
    <Tooltip
      title={
        <p className="HelpText-popper">
          <String id={id} />
        </p>
      }
      className={className}
      classes={{ tooltip: 'HelpText-tooltip' }}
      enterTouchDelay={0}
    >
      <IconButton classes={{ root: 'HelpText' }} size="small">
        <InfoIcon color="#8fe1ea" size={18} />
      </IconButton>
    </Tooltip>
  );
};

export default HelpText;
