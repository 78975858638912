/* eslint-disable react-hooks/exhaustive-deps */
import {
  IGetProductQuery,
  ILocale,
  IQueryProductArgs,
  IStepsType,
  useGetProductLazyQuery,
  useGetProductsQuery,
} from 'shared/graphql/api/types';
import { useMemo, useState } from 'react';
import Logger from '../utils/Logger';
import { IProduct } from '../types/product';

const logger = Logger.create('useProduct');

export type ProductHistory = {
  initial?: IProduct;
  current?: IProduct;
  latest?: IProduct;
};
export type LoadedProductHistory = {
  initial: IProduct;
  current: IProduct;
  latest: IProduct;
};

export const useProduct = (
  id: IQueryProductArgs['id'],
  locale: ILocale,
  cacheQuery = true
) => {
  const [productId, setProductId] = useState<string>(id);
  const [from, setFrom] = useState<Date>(new Date());
  const [latestProduct, setLatestProduct] = useState<
    IGetProductQuery | undefined
  >();
  const [initialProduct, setInitialProduct] = useState<
    IGetProductQuery | undefined
  >();
  const [currentProduct, setCurrentProduct] = useState<
    IGetProductQuery | undefined
  >();

  const [getProduct, { data, loading: loadingProduct, error }] =
    useGetProductLazyQuery({
      variables: { id: productId, locale },
      fetchPolicy: cacheQuery ? 'cache-first' : 'network-only',
    });

  let loading = true;

  useMemo(() => {
    //hit once
    if (data && !initialProduct) {
      setInitialProduct(data);
      setLatestProduct(data);
      setCurrentProduct(data);
    }
    // check to see if we have a new product
    if (data && data?.product.id !== latestProduct?.product.id) {
      setLatestProduct(data);
      if (from.getTime() < Date.now()) {
        // set current product
        logger.info('Using productId: ' + data.product.id);
        setCurrentProduct(data);
      }
    }
    if (data?.product?.productDeprecated?.successorId) {
      setProductId(data?.product?.productDeprecated?.successorId);
      setFrom(new Date(data?.product?.productDeprecated?.from));
    }
  }, [data]);

  useMemo(() => {
    getProduct({
      variables: { id: productId, locale },
    });
  }, [productId]);

  if (initialProduct && currentProduct && latestProduct && !loadingProduct) {
    loading = false;
  }

  if (error) {
    loading = false;
  }

  return {
    loading: loading,
    productHistory: {
      initial: initialProduct?.product,
      current: currentProduct?.product,
      latest: latestProduct?.product,
    },
    error: error,
  };
};

export const useProducts = (locale: ILocale, stepsType: IStepsType) => {
  const { loading, data, error } = useGetProductsQuery({
    variables: {
      locale,
      stepsType,
    },
  });

  return {
    loading,
    products: data?.products,
    error,
  };
};
