import {
  ICurrency,
  ILineItem,
  IProductDetailsFragment,
  IQuoteDetailsFragment,
  IQuotePackageValuation,
} from 'shared/graphql/api/types';

import configuration from 'configuration';
import classnames from 'classnames';

import {
  findInstalmentsPaymentPlan,
  getPriceBreakdown,
} from 'utils/quoteUtils';
import { formatTestId } from 'utils/testIdUtils';
import { WalletIcon } from 'components/Icons';
import RadioButton from 'components/Form/RadioButton/RadioButton';

import QuotePackageBreakdown from './QuoteOptionBreakdown';
import './QuoteOption.scss';

import QuoteOptionHighlights from './Feature/QuoteOptionHighlights';

import QuoteOptionPrice from './QuoteOptionPricing';
import { PolicyDuration } from 'utils/datasheet/types/datasheet-types';
import InstalmentsBanner from 'components/InstalmentsBanner/InstalmentsBanner';
import { getProductParameters } from 'components/PaymentOverview/paymentOverviewUtils';
import QuoteDocument from 'components/Documents/QuoteDocument';
import String from 'components/String/String';
import { getFeesLineItems } from 'utils/lineItems';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

interface Props {
  package: IQuotePackageValuation;
  product: IProductDetailsFragment;
  currency: ICurrency;
  policyDuration: PolicyDuration;
  onQuoteOptionClick(packageId: string): void;
  selectedPackage: boolean;
  protectedNcd: boolean;
  isRenewal: boolean;
  quote: IQuoteDetailsFragment;
}

function QuoteOption({
  package: _package,
  product,
  currency,
  policyDuration,
  onQuoteOptionClick,
  selectedPackage,
  protectedNcd,
  isRenewal,
  quote,
}: Props) {
  const valuation = _package.valuation;

  if (!valuation || _package.status === 'DECLINED') return null;

  const breakdown = getPriceBreakdown(
    valuation.total,
    policyDuration === 'DAYS_365'
  );

  const hasAllAddOns = configuration.allAddonsPackageIds.includes(_package.id);

  const showCostBreakdown = configuration.productsWithQuoteBreakdown.includes(
    product.code
  );

  const shouldShowInstalments = quote.packages.some(findInstalmentsPaymentPlan);

  const { showIpt } = getProductParameters(
    product.code,
    product.location.country
  );

  const {
    product: { documentTypes },
  } = quote;

  const quotePackageDocument = documentTypes.find(
    (docType) =>
      docType.dataModel === 'QUOTE' && docType.packageId === _package.id
  );

  const fees: ILineItem[] = getFeesLineItems(valuation);

  return (
    <div
      data-testid={`QuoteOption${selectedPackage ? '-selected' : ''}`}
      className={classnames('QuoteOption', {
        selected: selectedPackage,
        padded: shouldShowInstalments,
      })}
      role="button"
      onClick={() => onQuoteOptionClick(_package.id)}
    >
      {shouldShowInstalments && (
        <div
          className={classnames(
            `QuoteOption-color`,
            _package.name!.toLowerCase()
          )}
        />
      )}
      <div
        className="QuoteOption"
        data-testid={formatTestId(`quote-option-${_package.name}`)}
      >
        <div className="QuoteOption-header">
          <div className="QuoteOption-header-title">
            <RadioButton
              selected={selectedPackage}
              onClick={onQuoteOptionClick}
              id={_package.id}
              value={_package.id}
              name="quote-option"
              label={_package.name}
              testId={formatTestId(`select-${_package.name}`)}
            />
          </div>
          <div className="QuoteOption-header-icon">
            {_package.pricingModel === 'USAGE_BASED' && <WalletIcon />}
          </div>
        </div>
        <ErrorBoundary>
          <QuoteOptionPrice
            _package={_package}
            currency={currency}
            productCode={product.code}
            productCountry={product.location.country}
            policyDuration={policyDuration}
          />
        </ErrorBoundary>

        <InstalmentsBanner
          style={{ marginBottom: '1.5rem' }}
          isRenewal={isRenewal}
          visible={policyDuration === 'DAYS_365'}
          productId={product.id}
        />

        <QuoteOptionHighlights
          productCode={product.code}
          package={_package}
          quote={quote}
        />

        {showCostBreakdown && (
          <QuotePackageBreakdown
            currency={currency}
            breakdown={breakdown}
            hasAllAddOns={hasAllAddOns}
            protectedNcd={protectedNcd ?? false}
            showIpt={showIpt}
            fees={fees}
          />
        )}
        {quotePackageDocument && (
          <QuoteDocument
            typeId={quotePackageDocument.id}
            quote={quote}
            name={quotePackageDocument.name}
            key={quotePackageDocument.id}
            buttonClassName="QuoteOption-document"
          >
            <div
              className="QuoteOption-link"
              style={{ textDecoration: 'underline' }}
            >
              <String id={'pages.quote.package.whats_covered'} />
            </div>
            <i className="link-icon" />
          </QuoteDocument>
        )}
      </div>
    </div>
  );
}

export default QuoteOption;
