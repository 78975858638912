import Logger from './utils/Logger';
import configuration from './configuration';

const logger = Logger.create('AnonymousAuthClient');

const SESSION_STORAGE_KEY = 'guestToken';

class AnonymousAuthClient {
  private token: string | null = null;
  private readonly url: string = `${window.location.origin}/${configuration.region}/guestToken`;

  private async setupToken() {
    return this.fetchAnonUserToken()
      .then((token) => {
        logger.debug(`AnonymousAuthClient -> token: ${token}`);
        if (token) {
          this.setTokenInSessionStorage(token);
          this.token = token;
        }
      })
      .catch((error) => logger.error('Error fetching token:', error));
  }

  public getTokenSilently(): string | null {
    if (!this.token) {
      return this.getTokenFromSessionStorage();
    }
    return this.token;
  }

  public generateAnonymousToken() {
    if (configuration.releaseFlag.enableGuestQuote) {
      return this.setupToken();
    }
  }

  private getTokenFromSessionStorage(): string | null {
    return sessionStorage.getItem(SESSION_STORAGE_KEY);
  }

  private setTokenInSessionStorage(token: string) {
    sessionStorage.setItem(SESSION_STORAGE_KEY, token);
    logger.debug(`Token saved in session storage: ${token}`);
  }

  private fetchAnonUserToken = async () => {
    const response = await fetch(this.url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const result = await response.json();
    return result.token;
  };
}

export default AnonymousAuthClient;
