import Button from 'components/Buttons/Button';
import String from 'components/String/String';
import { ErrorMessage, Field, Form } from 'formik';
import { useEffect, useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { formatDate, isValid as isValidDate } from 'shared/utils/dateUtils';
import { useStrings } from 'hooks';
import { Licence } from './LicenceModal';

export interface Props {
  values: Licence;
  setFieldValue: any;
  isValid: boolean;
  dirty: boolean;
  generateLicence: any;
  builtLicence: string;
}

export function LicenceForm({
  builtLicence,
  values,
  setFieldValue,
  isValid,
  dirty,
  generateLicence,
}: Props) {
  const { get } = useStrings();
  const [dob, setDob] = useState<Date | null>(null);

  const getFormattedValue = (date: Date) => {
    return formatDate(date, 'dd-MM-yyyy');
  };

  const updateDate = (date: Date | null) => {
    if (date) {
      setFieldValue('dob', formatDate(date, 'yyyy-MM-dd'));
    }
    setDob(date);
  };

  useEffect(() => {
    generateLicence(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Form>
      <div className="LicenceModal-inputs LicenceModal-fields">
        <div className="LicenceModal-intro">
          <String id="pages.quote.licence.intro" />
        </div>
        <Field
          name="firstName"
          data-testid="licence-modal-first-name"
          autoComplete="off"
        />

        <Field
          name="lastName"
          data-testid="licence-modal-last-name"
          autoComplete="off"
        />

        <Field
          name="gender"
          as="select"
          data-testid="licence-modal-gender"
          placeholder={get('pages.quote.licence.gender')}
        >
          <option value="" disabled hidden>
            {get('pages.quote.licence.gender')}
          </option>
          <option value="F">{get('pages.quote.licence.gender.female')}</option>
          <option value="M">{get('pages.quote.licence.gender.male')}</option>
        </Field>
        <div className="LicenceModal-input-error">
          <ErrorMessage name="gender" />
        </div>

        <DatePicker
          id="dob"
          name="dob"
          views={['year', 'month', 'date']}
          openTo="year"
          className="DateInput-Picker"
          data-testid="licence-modal-dob"
          format="yyyy-MM-dd"
          value={dob}
          onChange={(date) => updateDate(date)}
          okLabel={get('pages.quote.date.ok')}
          cancelLabel={get('pages.quote.date.cancel')}
          inputVariant="outlined"
          TextFieldComponent={(params) => (
            <Field
              {...params}
              value={(isValidDate(dob) && getFormattedValue(dob)) || ''}
              placeholder={get('pages.quote.licence.dob')}
              data-testid="licence-modal-dob-display"
              autoComplete="off"
            />
          )}
        />
        <div className="LicenceModal-input-error">
          <ErrorMessage name="dob" />
        </div>

        <div className="LicenceModal-licence-display">
          <Field
            hidden={values.dob.length === 0 || values.gender.length === 0}
            disabled
            name="builtLicence"
            value={builtLicence}
            data-testid="licence-modal-built-licence"
            autoComplete="off"
            className="LicenceModal-licence-display-built"
          />

          <Field
            hidden={values.dob.length === 0 || values.gender.length === 0}
            name="endLicence"
            placeholder="Last 4 digits of your driving licence"
            data-testid="licence-modal-end-licence"
            autoComplete="off"
          />
        </div>
        <div className="LicenceModal-input-error">
          <ErrorMessage name="endLicence" />
        </div>
      </div>
      <div className="Modal-actions">
        <Button
          type="submit"
          variant="primary"
          disabled={!isValid || !dirty}
          testId="licence-modal-button"
        >
          <String id="pages.quote.licence.save" />
        </Button>
      </div>
    </Form>
  );
}

export default LicenceForm;
