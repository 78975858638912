import QuoteOverviewPage from 'pages/product/quote/QuoteOverviewPage/QuoteOverviewPage';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import QuotePage from './QuotePage';
import AuthRoute from 'components/Router/AuthRoute';
import QuoteGuestMigrate from './QuoteGuestMigrate';

function QuoteRoute({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route path={match.path} exact component={QuotePage} />
      <Route
        path={`${match.path}/:quoteId/package/:packageId/overview`}
        component={QuoteOverviewPage}
      />
      <AuthRoute
        path={`${match.path}/:quoteId/product/:productId/package/:packageId/migrate`}
        component={QuoteGuestMigrate}
      />
    </Switch>
  );
}

export default QuoteRoute;
