import React from 'react';

import { usePreQuote } from 'providers/QuoteProvider';
import { useStrings } from 'hooks';
import { isChoice } from 'utils/quoteUtils';
import { noop } from 'lodash';
import { Form, Formik } from 'formik';

import {
  AddressQuestionTypeEnum,
  CurrencyQuestionTypeEnum,
  DateQuestionTypeEnum,
  EligibilityQuestionTypeEnum,
  LicenceQuestionTypeEnum,
  PaymentQuestionTypeEnum,
  PoliciesQuestionTypeEnum,
  Question,
  TextQuestionTypeEnum,
  VehicleQuestionTypeEnum,
} from 'shared/types/question';

import TextInput from './TextInput/TextInput';
import ChoiceInput from './ChoiceInput/ChoiceInput';
import DateInput from './DateInput/DateInput';
import AddressInput from './AddressInput/AddressInput';
import PolicyInput from './PolicyInput/PolicyInput';
import PaymentInput from './PaymentInput/PaymentInput';
import MtaPaymentInput from './MtaPaymentInput/MtaPaymentInput';
import VehicleInput from './VehicleInput/VehicleInput';
import CurrencyInput from './CurrencyInput/CurrencyInput';
import EligibilityInput from './EligibilityInput/EligibilityInput';
import LicenceInput from './LicenceInput/LicenceInput';

interface Props {
  question: Question;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

function ChatbotInput({ question, scrollRef }: Props) {
  const { $, answerQuestion } = usePreQuote();
  const { get } = useStrings();
  return (
    <Formik initialValues={$.datasheet} onSubmit={noop}>
      <Form className="Chatbot-input">
        {isChoice(question) && (
          <ChoiceInput
            question={question}
            onAnswer={answerQuestion}
            scrollRef={scrollRef}
          />
        )}
        {question.type === TextQuestionTypeEnum.text && (
          <TextInput
            question={question}
            onAnswer={answerQuestion}
            scrollRef={scrollRef}
            placeholder={get('pages.quote.chatbot.placeholder')}
          />
        )}
        {question.type === LicenceQuestionTypeEnum.licence && (
          <LicenceInput
            question={question}
            onAnswer={answerQuestion}
            scrollRef={scrollRef}
            placeholder={get('pages.quote.chatbot.placeholder')}
          />
        )}
        {question.type === CurrencyQuestionTypeEnum.currency && (
          <CurrencyInput
            question={question}
            onAnswer={answerQuestion}
            scrollRef={scrollRef}
            placeholder={get('pages.quote.chatbot.placeholder')}
          />
        )}
        {question.type === DateQuestionTypeEnum.date && (
          <DateInput question={question} onAnswer={answerQuestion} />
        )}
        {question.type === AddressQuestionTypeEnum.address && (
          <AddressInput question={question} onAnswer={answerQuestion} />
        )}
        {question.type === PoliciesQuestionTypeEnum.policies && (
          <PolicyInput question={question} onAnswer={answerQuestion} />
        )}
        {question.type === PaymentQuestionTypeEnum.MTAPayment && (
          <MtaPaymentInput />
        )}
        {question.type === PaymentQuestionTypeEnum.payment && <PaymentInput />}
        {question.type === VehicleQuestionTypeEnum.vehicle && (
          <VehicleInput question={question} onAnswer={answerQuestion} />
        )}
        {question.type === EligibilityQuestionTypeEnum.eligibility && (
          <EligibilityInput question={question} onAnswer={answerQuestion} />
        )}
      </Form>
    </Formik>
  );
}

export default ChatbotInput;
