import String from 'components/String/String';
import {
  ICurrency,
  IFlexWalletPaymentPlan,
  IPackageValuationFragment,
} from 'shared/graphql/api/types';
import { getDepositTax, hasWalletServiceFee } from '../../utils/quoteUtils';
import Currency from '../Currency/Currency';
import configuration from 'configuration';
import HelpText from 'components/HelpText/HelpText';

interface FlexPaymentPlanBreakdownProps {
  paymentPlan: IFlexWalletPaymentPlan;
  currency: ICurrency;
  valuation: IPackageValuationFragment;
}

export function FlexPaymentPlanBreakdown({
  paymentPlan,
  currency,
}: FlexPaymentPlanBreakdownProps) {
  function getFeeAmount(walletFee: number): number {
    // We charge the fee every 30 days and the policy length is 90 days
    // hence each fee amount is a third of the total fee.
    return walletFee / 3;
  }
  return (
    <div
      className="PaymentPlanBreakdown-flex"
      data-testid="payment-plan-breakdown-flex"
    >
      <div>
        <String id="pages.quote.payment.overview.payment_plan.flex_wallet.price_title" />
      </div>
      <h1
        className="QuoteOption-price"
        data-testid="payment-plan-breakdown-flex-price"
      >
        {<Currency currency={currency} value={paymentPlan.policyBindAmount} />}{' '}
        <span className="Flex-wallet-deposit">
          <String id="pages.quote.payment.overview.payment_plan.flex_wallet.deposit" />
        </span>
      </h1>
      <div>
        <p
          className="Flex-wallet-info"
          data-testid="payment-plan-breakdown-flex-wallet-info"
        >
          <String
            id="pages.quote.payment.overview.payment_plan.flex_wallet.topup_info"
            values={{
              minWalletAmount: (
                <Currency
                  currency={currency}
                  value={paymentPlan.minWalletBalance}
                />
              ),
              topUpAmount: (
                <Currency currency={currency} value={paymentPlan.topupAmount} />
              ),
            }}
          />
        </p>

        <p
          className="QuoteOption-tax"
          data-testid="payment-plan-breakdown-ipt-flex"
        >
          <String
            id="pages.quote.payment.overview.payment_plan.deposit.ipt"
            values={{
              amount: (
                <Currency
                  currency={currency}
                  value={getDepositTax(paymentPlan)}
                />
              ),
            }}
          />
        </p>
        {hasWalletServiceFee(paymentPlan) &&
          configuration.walletFeesEnabled && (
            <>
              <p
                className="QuoteOption-tax"
                data-testid="payment-plan-breakdown-wallet-fees"
              >
                <String
                  id="pages.quote.payment.overview.payment_plan.deposit.wallet_fee_text"
                  values={{
                    feeAmount: (
                      <Currency
                        currency={currency}
                        value={getFeeAmount(paymentPlan.walletServiceFee!)}
                      />
                    ),
                    feeTotal: (
                      <Currency
                        currency={currency}
                        value={paymentPlan.walletServiceFee!}
                      />
                    ),
                  }}
                />
                <HelpText
                  id="pages.quote.payment.overview.payment_plan.deposit.wallet_fee_tooltip"
                  className="QuoteOption-wallet-fee-tooltip"
                />
              </p>
            </>
          )}
      </div>
    </div>
  );
}
