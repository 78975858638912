import { Builder } from 'builder-pattern';
import {
  Action,
  ActionStep,
  ActionStepTypeEnum,
  AddressQuestion,
  AddressQuestionTypeEnum,
  Choice,
  ChoiceQuestion,
  ChoiceQuestionTemplateEnum,
  ChoiceQuestionTypeEnum,
  Condition,
  ConditionStep,
  EmptyCondition,
  EqualsCondition,
  BeforeCondition,
  AfterCondition,
  ConditionStepTypeEnum,
  CopyActionTypeEnum,
  CurrencyQuestion,
  CurrencyQuestionTypeEnum,
  DateQuestion,
  DateQuestionTemplateEnum,
  DateQuestionTypeEnum,
  DefaultActionTypeEnum,
  EligibilityQuestion,
  EligibilityQuestionTypeEnum,
  ForkActionTypeEnum,
  ForkOption,
  PaymentQuestion,
  PaymentQuestionTypeEnum,
  PoliciesQuestion,
  PoliciesQuestionTypeEnum,
  Question,
  QuestionStep,
  QuestionStepTypeEnum,
  SetActionTypeEnum,
  StatementQuestion,
  StatementQuestionTypeEnum,
  Statements,
  TextQuestion,
  TextQuestionTemplateEnum,
  TextQuestionTypeEnum,
  VehicleQuestion,
  VehicleQuestionTemplateEnum,
  VehicleQuestionTypeEnum,
  SegmentStep,
  SegmentStepTypeEnum,
  SegmentTypeEnum,
  GreaterCondition,
  LessThanCondition,
  CounterActionTypeEnum,
} from '../types/question';

type ArrayOrSingle<T> = T | T[];
type AnswerPath = ArrayOrSingle<string>;
type QuestionType<T> = QuestionStep & { question: T };

export const question = <T>(id: string, question: Question) =>
  Builder<QuestionType<T>>({
    id,
    type: QuestionStepTypeEnum.question,
    question,
  });

export const text = (
  id: string,
  text: string,
  answer_path: AnswerPath,
  goTo: string,
  template = TextQuestionTemplateEnum.alphanumeric,
  name?: string
) =>
  question<TextQuestion>(id, {
    type: TextQuestionTypeEnum.text,
    template,
    text,
    answer_path,
    goTo,
    name,
  }).build();

export const currency = (
  id: string,
  text: string,
  answer_path: AnswerPath,
  goTo: string
) =>
  question<CurrencyQuestion>(id, {
    type: CurrencyQuestionTypeEnum.currency,
    text,
    answer_path,
    goTo,
  }).build();

export const address = (
  id: string,
  text: string,
  answer_path: AnswerPath,
  goTo: string
) =>
  question<AddressQuestion>(id, {
    type: AddressQuestionTypeEnum.address,
    text,
    answer_path,
    goTo,
  }).build();

export const email = (
  id: string,
  text: string,
  goTo: string,
  answer_path: AnswerPath
) =>
  question<TextQuestion>(id, {
    type: TextQuestionTypeEnum.text,
    template: TextQuestionTemplateEnum.email,
    text,
    answer_path,
    goTo,
  }).build();

export const numeric = (
  id: string,
  text: string,
  answer_path: AnswerPath,
  goTo: string
) =>
  question<TextQuestion>(id, {
    type: TextQuestionTypeEnum.text,
    template: TextQuestionTemplateEnum.numeric,
    text,
    answer_path,
    goTo,
  }).build();

export const statement = (id: string, text: string, goTo: string) =>
  question<StatementQuestion>(id, {
    type: StatementQuestionTypeEnum.statement,
    text,
    goTo,
  }).build();

export const date = (
  id: string,
  text: string,
  answer_path: AnswerPath,
  goTo: string,
  template = DateQuestionTemplateEnum.date
) =>
  question<DateQuestion>(id, {
    type: DateQuestionTypeEnum.date,
    template,
    text,
    answer_path,
    goTo,
  }).build();

export const dropdown = (
  id: string,
  text: string,
  choices: Array<Choice | string | number>,
  answer_path?: AnswerPath,
  searchable?: boolean
) =>
  choice(
    id,
    text,
    choices,
    answer_path,
    ChoiceQuestionTemplateEnum.dropdown,
    searchable
  );

export const yesno = (
  id: string,
  text: string,
  goTo: string[],
  answer_path?: AnswerPath
) =>
  choice(
    id,
    text,
    [option('YES', true, goTo[0]), option('NO', false, goTo[1])],
    answer_path
  );

const mapChoice = (choice: Choice | string | number) => {
  if (typeof choice === 'string') {
    return option(choice);
  }

  if (typeof choice === 'number') {
    return option(String(choice), choice);
  }

  return choice;
};

export const choice = (
  id: string,
  text: string,
  choices: Array<Choice | string | number>,
  answer_path?: AnswerPath,
  template = ChoiceQuestionTemplateEnum.button,
  searchable?: boolean
) =>
  question<ChoiceQuestion>(id, {
    type: ChoiceQuestionTypeEnum.choice,
    text,
    answer_path,
    template,
    choices: choices.map(mapChoice),
    searchable,
  }).build();

const defaultStatements = () => {
  return {
    youAre: {
      title: 'You are',
      values: [
        {
          gtm: 'some-gtm',
          text: 'some-statement-text',
        },
      ],
    },
    youHave: {
      title: 'You have',
      values: [
        {
          gtm: 'some-gtm',
          text: 'some-statement-text1',
        },
      ],
    },
    youWillNotBe: {
      title: 'You will not be',
      values: [
        {
          gtm: 'some-gtm',
          text: 'some-statement-text2',
        },
      ],
    },
  };
};

export const eligibility = (
  id: string,
  txt: string,
  choices: Array<Choice | string | number>,
  statements?: Statements,
  goTo?: string,
  answer_path?: AnswerPath,
  template = EligibilityQuestionTypeEnum.eligibility
) =>
  question<EligibilityQuestion>(id, {
    type: EligibilityQuestionTypeEnum.eligibility,
    text: txt,
    goTo: goTo || '',
    answer_path,
    statements: statements ?? defaultStatements(),
    confirmationTitle: 'some-confirmation-title',
    statementTitle: 'some-statement-title',
    choices: choices.map(mapChoice),
  }).build();

export const option = (
  text: string,
  value: Choice['value'] = undefined,
  goTo: string = 'NEXT'
): Choice => ({
  text,
  value: value ?? text,
  goTo,
});

export const policies = (
  id: string,
  text: string,
  answer_path: AnswerPath,
  goTo: string
) =>
  question<PoliciesQuestion>(id, {
    type: PoliciesQuestionTypeEnum.policies,
    text,
    answer_path,
    goTo,
  }).build();

export const payment = (
  id: string,
  text: string,
  answer_path: AnswerPath,
  goTo: string
) =>
  question<PaymentQuestion>(id, {
    type: PaymentQuestionTypeEnum.payment,
    text,
    answer_path,
    goTo,
  }).build();

export const vehicle = (
  id: string,
  text: string,
  answer_path: AnswerPath,
  template: VehicleQuestionTemplateEnum,
  goTo: string
) =>
  question<VehicleQuestion>(id, {
    type: VehicleQuestionTypeEnum.vehicle,
    template,
    text,
    answer_path,
    goTo,
  }).build();

export const action = (id: string, goTo: string, action: Action) =>
  Builder<ActionStep>({
    id,
    type: ActionStepTypeEnum.action,
    goTo,
    action,
  });

export const condition = (id: string, condition: Condition) =>
  Builder<ConditionStep>({
    id,
    type: ConditionStepTypeEnum.condition,
    condition,
  });

export const conditionStep = (
  id: string,
  ifCondition:
    | EmptyCondition
    | EqualsCondition
    | BeforeCondition
    | AfterCondition
    | GreaterCondition
    | LessThanCondition,
  elseCondition: string
) =>
  condition(id, {
    if: ifCondition,
    else: elseCondition,
  }).build();

export const copy = (id: string, from: string, to: string, goTo: string) =>
  action(id, goTo, {
    type: CopyActionTypeEnum.copy,
    from,
    to,
  }).build();

export const count = (id: string, path: string, goTo: string) =>
  action(id, goTo, {
    type: CounterActionTypeEnum.counter,
    path,
  }).build();

export const fork = (id: string, path: string, options: ForkOption[]) =>
  action(id, '', {
    type: ForkActionTypeEnum.fork,
    options,
    path,
  }).build();

export const set = (id: string, path: string, value: any, goTo: string) =>
  action(id, goTo, {
    type: SetActionTypeEnum.set,
    path,
    value,
  }).build();

export const skipSegment = (id: string, onFinishGoTo: string) =>
  Builder<SegmentStep>({
    id,
    type: SegmentStepTypeEnum.segment,
    segment: {
      type: SegmentTypeEnum.skip,
      featureName: '',
      onFinishGoTo,
    },
  }).build();

export const insertSegment = (
  id: string,
  onFinishGoTo: string,
  featureId: string,
  featureName: string
) =>
  Builder<SegmentStep>({
    id,
    type: SegmentStepTypeEnum.segment,
    segment: {
      type: SegmentTypeEnum.insert,
      featureName,
      ifFeature: featureId,
      onFinishGoTo,
    },
  }).build();

export const defaulted = (id: string, path: string, value: any, goTo: string) =>
  action(id, goTo, {
    type: DefaultActionTypeEnum.setDefault,
    path,
    value,
  }).build();
