import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ThemeProvider from 'providers/ThemeProvider';
import AuthProvider from 'providers/AuthProvider';
import StringProvider from 'providers/StringProvider';
import App from './App';
import GlobalStyle from 'themes/globalStyle';
import { useAnonymousClient, useAuthClient } from 'hooks/useAuthClient';
import LocaleProvider from './providers/LocaleProvider';
import Head from './components/Head/Head';
import NavigationProvider from './providers/NavigationProvider';
import ApolloProvider from './providers/ApolloProvider';
import Loader from 'components/Loader/Loader';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import DatePickerProvider from './providers/DatePickerProvider';
import ApplicationProvider from './providers/ApplicationProvider';
import IntlProvider from './providers/IntlProvider';
import ModalRoot from 'components/Modal/ModalRoot';
import ModalProvider from './providers/ModalProvider';
import configuration from './configuration';
import TrackingProvider from './providers/TrackingProvider';
import { PWAProvider } from 'providers/PWAProvider';
import MaintenanceBoundary from 'pages/maintenance/MaintenanceBoundary';

function Root() {
  const authClient = useAuthClient();
  const anonClient = useAnonymousClient();

  const mocked =
    configuration.environment === 'local' && configuration.disableAuth;

  if (!authClient) {
    return <Loader />;
  }

  // noinspection HtmlRequiredTitleElement
  return (
    <Router basename={configuration.region}>
      <PWAProvider>
        <LocaleProvider defaultValue={configuration.defaultLocale}>
          <MaintenanceBoundary>
            <AuthProvider
              client={authClient}
              anonClient={anonClient}
              mocked={mocked}
            >
              <TrackingProvider>
                <ApolloProvider>
                  <StringProvider>
                    <IntlProvider>
                      <ApplicationProvider>
                        <NavigationProvider>
                          <DatePickerProvider>
                            <ModalProvider>
                              <ThemeProvider>
                                <GlobalStyle />
                                <Head />
                                <ErrorBoundary>
                                  <App />
                                  <ModalRoot />
                                </ErrorBoundary>
                              </ThemeProvider>
                            </ModalProvider>
                          </DatePickerProvider>
                        </NavigationProvider>
                      </ApplicationProvider>
                    </IntlProvider>
                  </StringProvider>
                </ApolloProvider>
              </TrackingProvider>
            </AuthProvider>
          </MaintenanceBoundary>
        </LocaleProvider>
      </PWAProvider>
    </Router>
  );
}

export default Root;
