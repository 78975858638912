import { daysBetween } from 'shared/utils/dateUtils';
import {
  IPolicyDetailFragment,
  IQuoteDetailsFragment,
} from 'shared/graphql/api/types';

import { toTitleCase } from 'utils/stringUtils';
import { getMtaQuotes } from 'utils/policyUtils';

import { Flex1Col, FlexColumn } from 'components/Layout/Layout';
import String from 'components/String/String';
import DateFormat from 'components/Date/Date';

import PolicyActions from './PolicyActions';
import {
  FlexWalletPaymentPlanPaymentPlanEnum,
  FlexWalletPaymentPlan,
} from '@inshur/apis/quote';
import Currency from 'components/Currency/Currency';
import { PolicyStatus } from '@inshur/apis/policy';
import { useStrings } from 'hooks';
import InfoBox, { InfoBoxType } from 'components/InfoBox/InfoBox';
import WalletBalanceField from 'components/ProductDetails/WalletBalance';
import WalletBalanceColumn from 'components/ProductDetails/WalletBalanceColumn';
import { Configuration } from 'configuration';
import {
  ApplicationError,
  FailedToFetchFromCustomerApi,
  UserIdNotPresent,
  WalletDoesntExist,
} from 'constants/errors';
import { useUserId } from 'hooks/useUserId';
import { getProductLocale } from 'utils/productUtils';
import { useAuth } from '../../../../providers/AuthProvider';
import Logger from 'utils/Logger';
const logger = Logger.create('PoliciesTab');

interface Props {
  activePendingPolicies?: IPolicyDetailFragment[];
  allPolicies?: IPolicyDetailFragment[];
  quotes?: IQuoteDetailsFragment[];
  policiesRefetch?: () => void;
}

interface PolicyVehicle {
  make: string;
  model: string;
  yearOfManufacture: string;
}

function PoliciesTab({
  activePendingPolicies = [],
  allPolicies = [],
  quotes = [],
  policiesRefetch = () => {},
}: Props) {
  const { get } = useStrings();
  const { accessToken } = useAuth();
  const userId = useUserId();

  const mtaQuotes = getMtaQuotes(quotes).slice().reverse();

  // This function originally returned true, if there existed ANY 1 policy that
  // was pending (no ids were checked). It was then commented out, since  users are able to buy
  // multiple policies, and hence could have multiple PENDING renewal policies. The function
  // has now been reinstated and refined to check if there exists a renewal that was created from
  // existing policy (by checking renewalOf id in the underlying quote)
  function hasPendingRenewedPolicy(policy: IPolicyDetailFragment) {
    return activePendingPolicies.some(
      ({ id, status, quote }) =>
        id !== policy.id &&
        status === 'PENDING' &&
        quote?.renewalOf?.id === policy.id
    );
  }

  function findPendingMtaPolicy(policy: IPolicyDetailFragment) {
    return allPolicies.find(({ id, status }) => {
      return id === policy.id && status === PolicyStatus.PENDING_MTA;
    });
  }

  function findPendingCancellationPolicy(policy: IPolicyDetailFragment) {
    return allPolicies.find(({ id, status }) => {
      return id === policy.id && status === PolicyStatus.PENDING_CANCELLATION;
    });
  }

  function getPolicyAllVersions(policy: IPolicyDetailFragment) {
    return allPolicies.filter(({ id }) => {
      return id === policy.id;
    });
  }

  if (!activePendingPolicies.length) {
    return null;
  }

  return (
    <>
      {activePendingPolicies
        ?.slice()
        .reverse()
        .map((policy, index) => {
          const {
            id,
            version,
            activeTo,
            activeFrom,
            canRenew,
            datasheet,
            status,
            package: _package,
            product,
          } = policy;
          const mtaEnabled = product?.settings?.mtaEnabled ?? false;
          const locale = getProductLocale(product);
          const isActive = status === 'ACTIVE';
          const daysUntilExpiry = daysBetween(new Date(), activeTo);

          //const allowRenewal = canRenew;

          // Adding back this logic (with renewalOf id check) to determine
          // whether a policy has been renewed.
          const hasPendingRenewed = hasPendingRenewedPolicy(policy);
          const allowRenewal = canRenew && !hasPendingRenewed;

          const pendingMtaPolicy = findPendingMtaPolicy(policy);
          const pendingCancellationPolicy =
            findPendingCancellationPolicy(policy);

          const mtaQuote = mtaQuotes.find(
            (q) =>
              q.mtaOf?.id === policy.id && q.mtaOf?.version === policy.version
          );

          const flexPaymentPlan =
            _package?.paymentPlan?.paymentPlan ===
            FlexWalletPaymentPlanPaymentPlanEnum.FLEX_WALLET
              ? (_package.paymentPlan as FlexWalletPaymentPlan)
              : undefined;

          return (
            <div
              key={id}
              className="ProductPage-policy"
              data-testid={`ProductPage-policy-${index}`}
            >
              <input type="hidden" value={id} id="policy" />
              <Flex1Col>
                <h2>{policy.policyNumber}</h2>
              </Flex1Col>
              {pendingMtaPolicy && (
                <InfoBox
                  title={get('pages.product.dashboard.pending_mta.title')}
                  text={get('pages.product.dashboard.pending_mta.message', {
                    date: (
                      <DateFormat
                        date={new Date(pendingMtaPolicy.activeFrom)}
                        timeZone={product.timezone}
                        locale={locale}
                        showTime
                      />
                    ),
                  })}
                  type={InfoBoxType.Info}
                  allowClose={false}
                  testId="pending-mta-policy-infobox"
                />
              )}
              {pendingCancellationPolicy && (
                <InfoBox
                  title={get(
                    'pages.product.dashboard.pending_cancellation.title'
                  )}
                  text={get(
                    'pages.product.dashboard.pending_cancellation.message',
                    {
                      date: (
                        <DateFormat
                          date={new Date(pendingCancellationPolicy.activeFrom)}
                          timeZone={product.timezone}
                          locale={locale}
                          showTime
                        />
                      ),
                      reason: get(
                        `enums.cancellation_reason.${pendingCancellationPolicy.datasheet.policy?.cancellation?.reason}`
                      ),
                    }
                  )}
                  type={InfoBoxType.Warn}
                  allowClose={false}
                  testId="pending-cancellation-policy-infobox"
                />
              )}
              <FlexColumn className="ProductPage-details">
                <Flex1Col>
                  <div className="field">
                    <String id="pages.product.dashboard.policy" />
                  </div>
                </Flex1Col>
                <Flex1Col>
                  <div className="value">{_package!.name}</div>
                </Flex1Col>
              </FlexColumn>
              <FlexColumn className="ProductPage-details">
                <Flex1Col>
                  <div className="field">
                    <String id="pages.product.dashboard.driver" />
                  </div>
                </Flex1Col>
                <Flex1Col>
                  <div className="value">
                    {`${datasheet.insured.firstName} ${datasheet.insured.lastName}`}
                  </div>
                </Flex1Col>
              </FlexColumn>
              {datasheet.vehicles.map((vehicle: PolicyVehicle, idx: number) => (
                <FlexColumn
                  key={`Vehicle-${idx}`}
                  className="ProductPage-details"
                >
                  <Flex1Col>
                    <div className="field">
                      <String id="pages.product.dashboard.vehicle" />
                    </div>
                  </Flex1Col>
                  <Flex1Col>
                    <div className="value">
                      {`${vehicle.yearOfManufacture} ${toTitleCase(
                        vehicle.make
                      )} ${toTitleCase(vehicle.model)}`}
                    </div>
                  </Flex1Col>
                </FlexColumn>
              ))}
              <FlexColumn className="ProductPage-details">
                <Flex1Col>
                  <div className="field">
                    <String id="pages.product.dashboard.status" />
                  </div>
                </Flex1Col>
                <Flex1Col>
                  <div className="value">
                    {status === 'ACTIVE' && (
                      <String id="pages.product.dashboard.active" />
                    )}
                    {status === 'PENDING' && (
                      <String id="pages.product.dashboard.pending" />
                    )}
                  </div>
                </Flex1Col>
              </FlexColumn>
              <FlexColumn className="ProductPage-details">
                <Flex1Col>
                  <div className="field">
                    <String id="pages.product.dashboard.start_date" />
                  </div>
                </Flex1Col>
                <Flex1Col>
                  <div className="value">
                    <DateFormat
                      date={new Date(activeFrom)}
                      timeZone={product.timezone}
                      locale={locale}
                    />
                  </div>
                </Flex1Col>
              </FlexColumn>
              <FlexColumn className="ProductPage-details">
                <Flex1Col>
                  <div className="field">
                    <String id="pages.product.dashboard.end_date" />
                  </div>
                </Flex1Col>
                <Flex1Col>
                  <div className="value">
                    <DateFormat
                      date={new Date(activeTo)}
                      timeZone={product.timezone}
                      locale={locale}
                    />
                    &nbsp;
                    <span
                      className={`ProductPage-info ${
                        daysUntilExpiry < 4 && 'alert'
                      }`}
                    >
                      (
                      <String
                        id="pages.product.dashboard.renewal_days"
                        values={{ days: daysUntilExpiry }}
                      />
                      )
                    </span>
                  </div>
                </Flex1Col>
              </FlexColumn>
              {flexPaymentPlan &&
                (Configuration.get().customerApi.isEnabled ? (
                  <>
                    <WalletBalanceField
                      isCustomerApiEnabled={
                        Configuration.get().customerApi.isEnabled
                      }
                      productId={product.id}
                      renderQueryError={({ error }) => {
                        const applicationError: ApplicationError = userId
                          ? FailedToFetchFromCustomerApi.from(
                              product.id,
                              userId,
                              'CUSTOMER_WALLET',
                              error
                            )
                          : UserIdNotPresent.from(
                              'User id not available when reporting CUSTOMER_WALLET query error'
                            );

                        logger.error(
                          'Error in finding customer wallet',
                          applicationError
                        );

                        return (
                          <WalletBalanceColumn>
                            <String id="pages.product.dashboard.wallet_query_error" />
                          </WalletBalanceColumn>
                        );
                      }}
                      renderWalletDoesntExist={() => {
                        const applicationError = userId
                          ? WalletDoesntExist.from(product.id, userId)
                          : UserIdNotPresent.from(
                              'User id not available when reporting CUSTOMER_WALLET query error'
                            );

                        logger.error('Wallet does not exist', applicationError);

                        return (
                          <WalletBalanceColumn>
                            <String id="pages.product.dashboard.wallet_doesnt_exist_error" />
                          </WalletBalanceColumn>
                        );
                      }}
                    />
                    <customer-wallet
                      token={'Bearer ' + accessToken}
                      env={Configuration.get().environment}
                    ></customer-wallet>
                  </>
                ) : (
                  <WalletBalanceColumn>
                    <Currency
                      currency={product.currency}
                      value={flexPaymentPlan.policyBindAmount}
                    />
                  </WalletBalanceColumn>
                ))}
              <br />
              <PolicyActions
                index={index}
                quotes={quotes}
                version={version}
                allowManualRenewal={allowRenewal}
                mtaQuote={mtaQuote}
                isActive={isActive}
                productId={product.id}
                productCode={product.code}
                policy={policy}
                mtaEnabled={mtaEnabled}
                hasPendingMtaPolicy={!!pendingMtaPolicy}
                policyAllVersions={getPolicyAllVersions(policy)}
                hasPendingRenewed={hasPendingRenewed}
                policiesRefetch={policiesRefetch}
              />
            </div>
          );
        })}
    </>
  );
}

export default PoliciesTab;
