import configuration from '../configuration';

export async function postRefererGoal(
  email: string | undefined,
  policyNumber: string,
  accessToken?: string
) {
  try {
    if (!configuration.ambassador.active) return;

    const { ambassadorCampaign, ambassadorCode } = JSON.parse(
      localStorage.getItem('ambassador') || '{}'
    );

    if (!ambassadorCampaign || !ambassadorCode || !accessToken) return;

    const ambassadorBody = {
      email,
      campaign_uid: ambassadorCampaign,
      short_code: ambassadorCode,
      policy_id: policyNumber,
    };

    const response = await fetch(
      `${configuration.ambassador.url}/referer/claim`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: JSON.stringify(ambassadorBody),
      }
    );
    if (response.status === 400) {
      console.log('Submission not valid', response);
    }
    if (response.status !== 200) {
      console.error('Issue with ambassador api', response);
    }
  } catch (e) {
    console.error(e);
  }
}

export function setAmbassadorValues(params: URLSearchParams) {
  const ambassadorCampaign = params.get('campaignid');
  const ambassadorCode = params.get('mbsy');
  if (ambassadorCampaign && ambassadorCode) {
    localStorage.setItem(
      'ambassador',
      JSON.stringify({
        ambassadorCampaign,
        ambassadorCode,
        dateSet: new Date().toISOString(),
      })
    );
  }
}
