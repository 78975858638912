import React, { PropsWithChildren, useState } from 'react';
import { setContext } from '@apollo/link-context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as ApolloClientProvider,
  createHttpLink,
  DefaultOptions,
  GraphQLRequest,
  InMemoryCache,
} from '@apollo/client';

import configuration from 'configuration';
import { IPush, useHistory } from 'hooks';
import { getProductId } from 'utils/urlUtils';
import { createRedirectPath } from 'utils/pageUtils';
import { GraphQLError } from 'constants/errors';

import result from 'shared/graphql/api/introspection';
import { useAuth } from './AuthProvider';
import _ from 'lodash';
import AnonymousAuthClient from '../AnonymousAuthClient';

const defaultHttpLink = createHttpLink({
  uri: ({ operationName }) =>
    `/${configuration.region}/api?operation=${operationName}`,
});
const customerApiHttpLink = createHttpLink({
  uri: configuration.customerApi.uri,
});

const cache = new InMemoryCache({
  possibleTypes: result.possibleTypes,
  typePolicies: {
    InstalmentPlan: {
      keyFields: ['policyId', 'version'],
    },
  },
});

export const createContext =
  (authClient?: Auth0Client, anonClient?: AnonymousAuthClient) =>
  async (_: GraphQLRequest, { headers }: any) => {
    const authenticated = await authClient?.isAuthenticated();

    if (configuration.releaseFlag.enableGuestQuote) {
      const guestToken = await anonClient?.getTokenSilently();

      if (guestToken) {
        headers = {
          ...headers,
          Guest: guestToken,
        };
      }

      if (!authenticated) {
        return {
          headers: {
            ...headers,
            Authorization: guestToken ? `Bearer ${guestToken}` : '',
          },
        };
      }

      const token = await authClient?.getTokenSilently();

      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    }

    if (!authenticated) {
      return { headers };
    }

    const token = await authClient?.getTokenSilently();

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  };

export function createOnError(push: IPush): ErrorHandler {
  return ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        Sentry.withScope((scope) => {
          scope.setTransactionName(operation.operationName);

          scope.setTag('operation', operation.operationName);
          scope.setExtra('Operation', operation.operationName);
          scope.setExtra('Code', error.extensions?.code);
          scope.setExtra('Reason', error.extensions?.reason);

          if (operation.query.loc) {
            scope.setExtra('Query', operation.query.loc.source.body.trim());
          }

          if (error.path) {
            scope.setExtra('Error Path', error.path.join(' > '));
          }

          Sentry.captureException(new GraphQLError(error.message));
        });
      });
    }

    const forbidden = graphQLErrors?.some((error) => {
      return error.message.split(' ').includes('403');
    });

    // Certain products can only be accessed by certain accounts (inshur, uber, etc).
    // If the product query is forbidden then we redirect the user to the login
    // page in an attempt to get them to authenticate with the right account.
    if (operation.operationName === 'GetProduct' && forbidden) {
      const productId = getProductId(window.location.pathname);
      const redirect = createRedirectPath(window.location);

      push(`/product/${productId}/login`, { redirect });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  };
}

function ApolloProvider({ children }: PropsWithChildren<{}>) {
  const { client: authClient, anonClient } = useAuth();
  const { push } = useHistory();

  const errorLink = onError(createOnError(push));

  /**
   * Omits the __typeName from graphql variables
   *
   * The __typename is not normally present, but because we patch the responses from the api
   * to the datasheet, the datasheet now will contain the unwanted __typename.
   *
   * This ONLY affects operation variables and not responses or caching
   */
  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (!_.isEmpty(operation.variables)) {
      const omitTypename = (key: string, value: unknown) =>
        key === '__typename' ? undefined : value;
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      );
    }
    return forward(operation);
  });

  const [client] = useState(() => {
    const auth = setContext(createContext(authClient, anonClient));

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    };

    return new ApolloClient({
      link: ApolloLink.from([
        cleanTypeName,
        auth,
        errorLink,
        ApolloLink.split(
          (operation) => {
            if (configuration.customerApi.isEnabled) {
              return operation.getContext().clientName === 'customer-api';
            }

            return false;
          },
          customerApiHttpLink,
          defaultHttpLink
        ),
      ]),
      cache,
      defaultOptions,
    });
  });

  return (
    <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
  );
}

export default ApolloProvider;
