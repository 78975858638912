import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import { ErrorCodes, ProductError } from 'constants/errors';
import { useQuote, useHistory, useExpireQuote, useStrings } from 'hooks';
import { useLocale } from 'providers/LocaleProvider';

import Page from 'components/Page/Page';
import String from 'components/String/String';
import Loader from 'components/Loader/Loader';
import Button from 'components/Buttons/Button';
import { Centered } from 'components/Layout/Layout';
import InfoBox, { InfoBoxType } from 'components/InfoBox/InfoBox';
import Table, {
  TABLE_ORIENTATION,
  TableDataItem,
} from 'components/Table/Table';
import QuoteOption from 'components/QuoteOption/QuoteOption';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import {} from 'hooks';
import configuration from 'configuration';
import LinkButton from 'components/LinkButton/LinkButton';
import { RenewalBanner } from '../RenewalBanner/RenewalBanner';
import {
  adjustedQuoteStartDate,
  openQuotesRenewalsEnabled,
  isUSProduct,
} from '../../../../utils/quoteUtils';
import { getProductLocale } from 'utils/productUtils';
import { QuoteStatusEnum } from '@inshur/apis/quote';
import QuoteOptionMessage from 'components/QuoteOption/QuoteOptionMessage';

import './QuoteOptionsPage.scss';

function QuoteOptionsPage() {
  const { id, quoteId } = useParams<{ id: string; quoteId: string }>();
  const { lang: language } = useLocale();
  const { get } = useStrings();
  const { push } = useHistory();

  const [hasErrors, setHasErrors] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<string>();

  const { quote, loading: loadingQuote } = useQuote(quoteId, true);

  const showRenewalBanner =
    openQuotesRenewalsEnabled() && quote?.renewalOf != null;

  useExpireQuote(false);

  useEffect(() => {
    if (selectedPackage) setHasErrors(false);
  }, [hasErrors, selectedPackage]);

  if (loadingQuote) {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  if (!quote || !quote.product) {
    throw new ProductError(id, ErrorCodes.PRODUCT_NOT_FOUND);
  }
  const policyConditions = `${configuration.documents.url}/products/${quote.product?.code}/policy-conditions.${language}.pdf`;
  const { product } = quote;
  const locale = getProductLocale(product);

  function handleProgression() {
    if (selectedPackage) {
      push(
        `/product/${id}/quote/${quoteId}/package/${selectedPackage}/overview`,
        {}
      );
    }

    setHasErrors(true);
  }

  const tableData: Array<TableDataItem> = [
    {
      heading: get('pages.quote.summary.valid_until'),
      content: [quote.validUntil],
    },
    {
      heading: get('pages.quote.summary.startDate'),
      content: [adjustedQuoteStartDate(quote).toISOString()],
    },
  ];

  return (
    <Page name="QuoteOptionsPage" slug="Quote Options" padded={false}>
      {showRenewalBanner && <RenewalBanner />}

      <section className="QuoteOptionsPage-policy-header">
        <h1 className="title">
          <String id="pages.quote.summary.quote_options" />
        </h1>
        <div>
          {quote.status === QuoteStatusEnum.OK ? (
            <String id="pages.quote.summary.select_quote" />
          ) : (
            <String id="pages.quote.options.refer.subtitle" />
          )}
        </div>

        {hasErrors && (
          <div className="InfoBox-wrapper">
            <InfoBox
              type={InfoBoxType.Error}
              text={get('pages.quote.options.error.description')}
              title={get('pages.quote.options.error.title')}
              testId="QuoteOptions-no-selection"
              allowClose={false}
            />
          </div>
        )}
        <div className="QuoteOptionsPage-reference">
          <div className="reference-info">
            <String id="pages.quote.summary.reference" />
            <h2 className="quote-ref">{quote.quoteNumber}</h2>
            <p className="vehicle-name">{`${quote.datasheet.vehicles[0].make} ${quote?.datasheet.vehicles[0].model}`}</p>
          </div>
        </div>
        <Table
          data={tableData}
          timezone={product?.timezone}
          locale={locale}
          orientation={TABLE_ORIENTATION.Vertical}
        />
      </section>

      <div className="QuoteOptionsPage-options-wrapper">
        {quote.status === QuoteStatusEnum.REFER && (
          <QuoteOptionMessage
            title={
              <String id={'pages.quote.options.refer.info_message.title'} />
            }
            message={
              <String id={'pages.quote.options.refer.info_message.text'} />
            }
          />
        )}
        {quote.status === QuoteStatusEnum.OK &&
          quote.packages.map((_package) => {
            return _package.status === 'OK' ? (
              <QuoteOption
                selectedPackage={selectedPackage === _package.id}
                onQuoteOptionClick={setSelectedPackage}
                key={_package.id}
                package={_package}
                product={product}
                currency={product?.currency!}
                policyDuration={quote.datasheet?.policy?.duration}
                protectedNcd={quote.datasheet?.insured?.protectedNcd}
                isRenewal={quote.renewalOf !== null}
                quote={quote}
              />
            ) : null;
          })}

        {hasErrors && (
          <ErrorMessage message={get('pages.quote.options.error.required')} />
        )}

        {product && !isUSProduct(product) && (
          <p className="QuoteOptionsPage-conditions">
            <a
              className="QuoteOptionsPage-link-small"
              target="_blank"
              rel="noreferrer noopener"
              href={policyConditions}
              tabIndex={2}
            >
              <String id="pages.quote.options.conditions" />
            </a>
          </p>
        )}

        <LinkButton
          testId="btn-to-dashboard"
          className="ProductPage-button"
          to={`/product/${id}/`}
          variant="secondary"
        >
          <String id="pages.quote.summary.back_to_dashboard" />
        </LinkButton>
      </div>

      {quote.status === QuoteStatusEnum.OK && (
        <div
          className={classnames('QuoteOptionsPage-actions', {
            active: selectedPackage,
          })}
        >
          <Button
            onClick={handleProgression}
            variant="primary"
            testId={selectedPackage ? 'btn-continue' : 'btn-unselected'}
          >
            <String id="pages.quote.summary.continue_to_review" />
          </Button>
        </div>
      )}
    </Page>
  );
}

export default QuoteOptionsPage;
