import React from 'react';
import {
  LicenceQuestion,
  LicenceQuestionProviderEnum,
  TextQuestionTemplateEnum,
  TextQuestionTypeEnum,
} from 'shared/types/question';
import { AnswerQuestion } from 'providers/QuoteProvider';
import TextInput from '../TextInput/TextInput';
import configuration from 'configuration';
import UnstyledButton from 'components/Buttons/UnstyledButton';
import String from 'components/String/String';
import LicenceModal from './LicenceModal/LicenceModal';
import { getDriverNumber } from './LicenceModal/Licence.utils';

interface Props {
  question: LicenceQuestion;
  onAnswer: AnswerQuestion;
  placeholder?: string;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

function MyLicenceComponent({
  onAnswer,
  question,
}: {
  onAnswer: AnswerQuestion;
  question: LicenceQuestion;
}) {
  const [displayModal, setDisplayModal] = React.useState(false);

  const driverNumber = getDriverNumber(question.answer_path);

  const onClose = (values: {
    licence: string;
    firstName: string;
    lastName: string;
  }) => {
    setDisplayModal(false);

    if (!values) {
      return;
    }

    onAnswer(values.licence, {
      firstName: values.firstName,
      lastName: values.lastName,
      driversLicense: { dln: values.licence },
    });
  };

  return (
    <div className="Chatbot-actions">
      <LicenceModal
        active={displayModal}
        onClose={onClose}
        data-testid="licence-modal"
        driverNumber={driverNumber}
      />
      <UnstyledButton
        className="Chatbot-action"
        onClick={() => setDisplayModal(true)}
        data-testid="licence-button"
      >
        <String id="pages.quote.chatbot.search_licence" />
      </UnstyledButton>
    </div>
  );
}

function LicenceInput({ question, onAnswer, placeholder, scrollRef }: Props) {
  if (
    configuration.releaseFlag.enableLicenceInput &&
    question.provider === LicenceQuestionProviderEnum.MYLICENCE
  ) {
    return <MyLicenceComponent onAnswer={onAnswer} question={question} />;
  }

  function onAnswerLicence(licence: string) {
    onAnswer(licence, {
      driversLicense: { dln: licence },
    });
  }

  return (
    <TextInput
      question={{
        ...question,
        answer_path: `${question.answer_path}.driversLicense.dln`,
        type: TextQuestionTypeEnum.text,
        template: TextQuestionTemplateEnum.alphanumeric,
      }}
      onAnswer={onAnswerLicence}
      scrollRef={scrollRef}
      placeholder={placeholder}
      data-testid="licence-text-input"
    />
  );
}

export default LicenceInput;
