import { Path } from '@inshur/apis/steps';
import { isValid as isValidDate } from 'shared/utils/dateUtils';

export interface DriverData {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob: string;
  isFemale: boolean;
}

// Default date used when no date or wrong date is provided
const DEFAULT_INITIAL_DATE = '2024-01-01';

function cleanText(str: string): string {
  return str
    .normalize('NFD') // Normalize
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .replace(/[^a-zA-Z]/g, '') // Remove non-alphabetic characters
    .toUpperCase();
}

function padEndWith9(str: string, length: number): string {
  return str.padEnd(length, '9');
}

export function buildDriverLicence(data: DriverData): string {
  const { firstName = '', lastName = '', dob, isFemale } = data;

  const dobDate = isValidDate(new Date(dob))
    ? new Date(dob)
    : new Date(DEFAULT_INITIAL_DATE);

  const cleanedFirstName = cleanText(firstName);
  const firstNamePart: string =
    cleanedFirstName.length > 0 ? cleanedFirstName[0] : '9';

  const cleanedLastName = cleanText(lastName);
  const surnamePart: string = padEndWith9(cleanedLastName.slice(0, 5), 5);

  const yearOfBirth: number = dobDate.getFullYear();
  const decadeDigit: number = Math.floor(yearOfBirth / 10) % 10;
  const yearDigit: number = yearOfBirth % 10;
  const monthOfBirth: number = dobDate.getMonth() + 1;
  const monthLicence: number = isFemale ? monthOfBirth + 50 : monthOfBirth;

  const monthPart = monthLicence.toString().padStart(2, '0');
  const dayOfBirth = dobDate.getDate();
  const dayPart = dayOfBirth.toString().padStart(2, '0');

  const licenceNumber: string =
    surnamePart + decadeDigit + monthPart + dayPart + yearDigit + firstNamePart;

  return licenceNumber;
}

export function getDriverNumber(driverPath?: Path): number {
  if (!driverPath) {
    return 0;
  }

  if (Array.isArray(driverPath)) {
    return parseInt(driverPath[0].split('[')[1].split(']')[0], 10);
  }

  return parseInt(driverPath?.split('[')[1].split(']')[0], 10);
}
