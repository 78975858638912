import React, { createContext, useContext } from 'react';
import {
  GetUserDocument,
  IApiActionResponse,
  IGetDriverDetailsMutationVariables,
  IGetDriverDetailsUsMutationVariables,
  IGetTlcLicenseYearsHeldMutationVariables,
  IGetVehicleDetailsMutationVariables,
  IGetVehicleDetailsUsMutationVariables,
  IUpdateMarketingPreferencesMutationVariables,
  IGetUberDriverDetailMutationVariables,
  useGetDriverDetailsMutation,
  useGetDriverDetailsUsMutation,
  useGetTlcLicenseYearsHeldMutation,
  useGetVehicleDetailsMutation,
  useGetVehicleDetailsUsMutation,
  useUpdateMarketingPreferencesMutation,
  useGetUberDriverDetailMutation,
  IVerifyCoiMutationVariables,
  useVerifyCoiMutation,
} from '../shared/graphql/api/types';
import { noop } from 'lodash';
import { ApiActionHandlerEnum } from '@inshur/apis/steps';
import { useLazyQueryAsync } from '../hooks/useLazyQueryAsync';

export type IApiCallVariables =
  | IGetTlcLicenseYearsHeldMutationVariables
  | IUpdateMarketingPreferencesMutationVariables
  | IGetDriverDetailsMutationVariables
  | IGetDriverDetailsUsMutationVariables
  | IGetVehicleDetailsUsMutationVariables
  | IGetVehicleDetailsMutationVariables
  | IGetUberDriverDetailMutationVariables
  | IVerifyCoiMutationVariables;

export type ICallApiHandler = (
  type: ApiActionHandlerEnum,
  variables: IApiCallVariables
) => Promise<IApiActionResponse | undefined>;

export const CallApiHandlerContext = createContext<ICallApiHandler>(
  noop as any
);

export const useCallApiHandler = () => useContext(CallApiHandlerContext);

function CallApiHandlerProvider({ children }: React.PropsWithChildren<{}>) {
  const [setMarketingPreferences] = useUpdateMarketingPreferencesMutation();
  const [getDriverDetails] = useGetDriverDetailsMutation();
  const [getDriverDetailsUs] = useGetDriverDetailsUsMutation();
  const [getVehicleDetails] = useGetVehicleDetailsMutation();
  const [getVehicleDetailsUs] = useGetVehicleDetailsUsMutation();
  const [getTlcYearsHeld] = useGetTlcLicenseYearsHeldMutation();
  const [getUberDriverDetail] = useGetUberDriverDetailMutation();
  const [verifyCoi] = useVerifyCoiMutation();
  const getUser = useLazyQueryAsync(GetUserDocument);

  const handlers = {
    [ApiActionHandlerEnum.SET_MARKETING_PREFERENCES]: setMarketingPreferences,
    [ApiActionHandlerEnum.GET_DRIVER_DETAILS]: getDriverDetails,
    [ApiActionHandlerEnum.GET_DRIVER_DETAILS_US]: getDriverDetailsUs,
    [ApiActionHandlerEnum.GET_VEHICLE_DETAILS]: getVehicleDetails,
    [ApiActionHandlerEnum.GET_VEHICLE_DETAILS_US]: getVehicleDetailsUs,
    [ApiActionHandlerEnum.GET_USER]: getUser,
    [ApiActionHandlerEnum.GET_TLC_YEARS_HELD]: getTlcYearsHeld,
    [ApiActionHandlerEnum.GET_UBER_DRIVER_DETAILS]: getUberDriverDetail,
    [ApiActionHandlerEnum.VERIFY_COI]: verifyCoi,
  };

  async function handler(
    type: ApiActionHandlerEnum,
    variables: IApiCallVariables
  ) {
    if (type === ApiActionHandlerEnum.GET_USER) {
      const { data } = await handlers[type]({});
      return { success: true, data: data?.user };
    } else {
      const { data } = await handlers[type]({ variables } as any);

      return data?.response;
    }
  }

  return (
    <CallApiHandlerContext.Provider value={handler}>
      {children}
    </CallApiHandlerContext.Provider>
  );
}

export default CallApiHandlerProvider;
