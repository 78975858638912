// Build a list of environment variables
import { ILocale } from 'shared/graphql/api/types';
import { FileHandlerMethod } from './utils/fileUtils';
// import Logger from 'utils/Logger';

// const logger = Logger.create('configuration');
const env: Record<string, string> = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_BUILD_VERSION: process.env.REACT_APP_BUILD_VERSION,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  REACT_APP_PUBLIC_API_BASE_URL: process.env.REACT_APP_PUBLIC_API_BASE_URL,
  REACT_APP_DOCUMENT_API_PATH: process.env.REACT_APP_DOCUMENT_API_PATH,
  REACT_APP_BILLING_API_PATH: process.env.REACT_APP_BILLING_API_PATH,
  REACT_APP_FLEX_API_LOGIN_PATH: process.env.REACT_APP_FLEX_API_LOGIN_PATH,
  REACT_APP_TRANSIENT_QUERY_PARAMS:
    process.env.REACT_APP_TRANSIENT_QUERY_PARAMS,
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  REACT_APP_GOOGLE_TAG_MANAGER_AUTH:
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
  REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW:
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW,
  REACT_APP_UKC_ID: process.env.REACT_APP_UKC_ID,
  REACT_APP_UKC2_ID: process.env.REACT_APP_UKC2_ID,
  REACT_APP_UKO2_ID: process.env.REACT_APP_UKO2_ID,
  REACT_APP_UKO3_ID: process.env.REACT_APP_UKO3_ID,
  REACT_APP_UKR2_ID: process.env.REACT_APP_UKR2_ID,
  REACT_APP_UKR3_ID: process.env.REACT_APP_UKR3_ID,
  REACT_APP_UKA_ID: process.env.REACT_APP_UKA_ID,
  REACT_APP_UKA2_ID: process.env.REACT_APP_UKA2_ID,
  REACT_APP_UKB_ID: process.env.REACT_APP_UKB_ID,
  REACT_APP_UKB2_ID: process.env.REACT_APP_UKB2_ID,
  REACT_APP_UKBL_ID: process.env.REACT_APP_UKBL_ID,
  REACT_APP_UKBL2_ID: process.env.REACT_APP_UKBL2_ID,
  REACT_APP_UKOG_ID: process.env.REACT_APP_UKOG_ID,
  REACT_APP_UKOG2_ID: process.env.REACT_APP_UKOG2_ID,
  REACT_APP_WAKAM_UK_PRODUCT_START_TIME:
    process.env.REACT_APP_WAKAM_UK_PRODUCT_START_TIME,
  REACT_APP_WAKAM_PRODUCT_REDIRECT:
    process.env.REACT_APP_WAKAM_PRODUCT_REDIRECT,
  REACT_APP_ALLOW_QUOTE_WHEN_ACTIVE_POLICY_FOR_USER_PRODUCT_CODES:
    process.env.REACT_APP_ALLOW_QUOTE_WHEN_ACTIVE_POLICY_FOR_USER_PRODUCT_CODES,
  REACT_APP_ENABLE_FORCE_QUOTE: process.env.REACT_APP_ENABLE_FORCE_QUOTE,
  REACT_APP_NBV_STRIPE_ACCOUNT: process.env.REACT_APP_NBV_STRIPE_ACCOUNT,
  REACT_APP_NBVO_STRIPE_ACCOUNT: process.env.REACT_APP_NBVO_STRIPE_ACCOUNT,
  REACT_APP_UKB_STRIPE_ACCOUNT: process.env.REACT_APP_UKB_STRIPE_ACCOUNT,
  REACT_APP_UKC_STRIPE_ACCOUNT: process.env.REACT_APP_UKC_STRIPE_ACCOUNT,
  REACT_APP_UKR_STRIPE_ACCOUNT: process.env.REACT_APP_UKR_STRIPE_ACCOUNT,
  REACT_APP_UKR2_STRIPE_ACCOUNT: process.env.REACT_APP_UKR2_STRIPE_ACCOUNT,
  REACT_APP_UKO_STRIPE_ACCOUNT: process.env.REACT_APP_UKO_STRIPE_ACCOUNT,
  REACT_APP_UKO2_STRIPE_ACCOUNT: process.env.REACT_APP_UKO2_STRIPE_ACCOUNT,
  REACT_APP_NYR_STRIPE_ACCOUNT: process.env.REACT_APP_NYR_STRIPE_ACCOUNT,
  REACT_APP_NYRS_STRIPE_ACCOUNT: process.env.REACT_APP_NYRS_STRIPE_ACCOUNT,
  REACT_APP_AZR_STRIPE_ACCOUNT: process.env.REACT_APP_AZR_STRIPE_ACCOUNT,
  REACT_APP_UKA_STRIPE_ACCOUNT: process.env.REACT_APP_UKA_STRIPE_ACCOUNT,
  REACT_APP_UKOG_STRIPE_ACCOUNT: process.env.REACT_APP_UKOG_STRIPE_ACCOUNT,
  REACT_APP_UKA2_STRIPE_ACCOUNT: process.env.REACT_APP_UKA2_STRIPE_ACCOUNT,
  REACT_APP_UKC2_STRIPE_ACCOUNT: process.env.REACT_APP_UKC2_STRIPE_ACCOUNT,
  REACT_APP_UKOG2_STRIPE_ACCOUNT: process.env.REACT_APP_UKOG2_STRIPE_ACCOUNT,
  REACT_APP_UKR3_STRIPE_ACCOUNT: process.env.REACT_APP_UKR3_STRIPE_ACCOUNT,
  REACT_APP_UKO3_STRIPE_ACCOUNT: process.env.REACT_APP_UKO3_STRIPE_ACCOUNT,
  REACT_APP_UKB2_STRIPE_ACCOUNT: process.env.REACT_APP_UKB2_STRIPE_ACCOUNT,
  REACT_APP_UKBL2_STRIPE_ACCOUNT: process.env.REACT_APP_UKBL2_STRIPE_ACCOUNT,
  REACT_APP_US_R_CO_STRIPE_ACCOUNT:
    process.env.REACT_APP_US_R_CO_STRIPE_ACCOUNT,
  REACT_APP_US_R_CA_STRIPE_ACCOUNT:
    process.env.REACT_APP_US_R_CA_STRIPE_ACCOUNT,
  REACT_APP_US_R_NY_STRIPE_ACCOUNT:
    process.env.REACT_APP_US_R_NY_STRIPE_ACCOUNT,
  REACT_APP_US_R_TX_STRIPE_ACCOUNT:
    process.env.REACT_APP_US_R_TX_STRIPE_ACCOUNT,
  REACT_APP_US_R_WA_STRIPE_ACCOUNT:
    process.env.REACT_APP_US_R_WA_STRIPE_ACCOUNT,
  REACT_APP_US_R_GA_STRIPE_ACCOUNT:
    process.env.REACT_APP_US_R_GA_STRIPE_ACCOUNT,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_UKA:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UKA,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_UKC:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UKC,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_UKR:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UKR,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_UKR2:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UKR2,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_UKO:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UKO,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_UKO2:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UKO2,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_UKOG:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UKOG,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_SANDBOX:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_SANDBOX,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_NBV:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_NBV,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_NYR:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_NYR,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_NYRS:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_NYRS,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_AZR:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_AZR,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_CA:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_CA,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_CO:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_CO,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_GA:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_GA,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_NY:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_NY,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_TX:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_TX,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_WA:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_WA,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_UK_WAKAM:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UK_WAKAM,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV0:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV0,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST0:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST0,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD0:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD0,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NYR:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NYR,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NYRS:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NYRS,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_AZR:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_AZR,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_CA:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_CA,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_CO:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_CO,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_GA:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_GA,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_NY:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_NY,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_TX:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_TX,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_WA:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_WA,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKA:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKA,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKO2:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKO2,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKR2:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKR2,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKC:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKC,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKOG:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKOG,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NBV:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NBV,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NBVO:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NBVO,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UK_WAKAM:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UK_WAKAM,
  REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_SANDBOX:
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_SANDBOX,
  REACT_APP_LOQATE_API_KEY: process.env.REACT_APP_LOQATE_API_KEY,
  REACT_APP_QUOTE_START_DATE_LEAD_TIME:
    process.env.REACT_APP_QUOTE_START_DATE_LEAD_TIME,
  REACT_APP_DOCUMENTS_API_URL: process.env.REACT_APP_DOCUMENTS_API_URL,
  REACT_APP_DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE,
  REACT_APP_STRIPE_EXTERNAL_PAYMENT_TIMEOUT:
    process.env.REACT_APP_STRIPE_EXTERNAL_PAYMENT_TIMEOUT,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_ENABLE_DEV_TOOLS: process.env.REACT_APP_ENABLE_DEV_TOOLS,
  REACT_APP_REGION: process.env.REACT_APP_REGION,
  REACT_APP_MARKETING_SITE_URL: process.env.REACT_APP_MARKETING_SITE_URL,
  REACT_APP_ENFORCE_VERIFICATION: process.env.REACT_APP_ENFORCE_VERIFICATION,
  REACT_APP_FILE_HANDLER_METHOD: process.env.REACT_APP_FILE_HANDLER_METHOD,
  REACT_APP_ENABLE_AUTO_COMPLETE: process.env.REACT_APP_ENABLE_AUTO_COMPLETE,
  REACT_APP_ENABLE_CHAT_DELAY: process.env.REACT_APP_ENABLE_CHAT_DELAY,
  REACT_APP_ENABLE_EVIL_AVATAR: process.env.REACT_APP_ENABLE_EVIL_AVATAR,
  REACT_APP_ENABLE_LINE_ITEMS: process.env.REACT_APP_ENABLE_LINE_ITEMS,
  REACT_APP_ENABLE_GUEST_QUOTE: process.env.REACT_APP_ENABLE_GUEST_QUOTE,
  REACT_APP_ENABLE_LICENCE_INPUT: process.env.REACT_APP_ENABLE_LICENCE_INPUT,
  REACT_APP_ENABLE_DISPLAY_LATEST_PRODUCTS:
    process.env.REACT_APP_ENABLE_DISPLAY_LATEST_PRODUCTS,
  REACT_APP_ENABLE_TRANSLATIONS_FEES:
    process.env.REACT_APP_ENABLE_TRANSLATIONS_FEES,
  REACT_APP_STRIPE_ACCOUNT_FROM_CONFIG:
    process.env.REACT_APP_STRIPE_ACCOUNT_FROM_CONFIG,
  REACT_APP_ENABLE_ALL_PAYMENT_OPTIONS_FROM_BILLING:
    process.env.REACT_APP_ENABLE_ALL_PAYMENT_OPTIONS_FROM_BILLING,
  REACT_APP_DISABLE_SELF_SERVE: process.env.REACT_APP_DISABLE_SELF_SERVE,
  REACT_APP_SELF_SERVE_PRODUCT_CODES:
    process.env.REACT_APP_SELF_SERVE_PRODUCT_CODES,
  REACT_APP_GUEST_QUOTE_PRODUCT_CODES:
    process.env.REACT_APP_GUEST_QUOTE_PRODUCT_CODES,
  REACT_APP_QUOTE_FORM_PRODUCT_CODES:
    process.env.REACT_APP_QUOTE_FORM_PRODUCT_CODES,
  REACT_APP_DISABLE_CREATE_POLICY: process.env.REACT_APP_DISABLE_CREATE_POLICY,
  REACT_APP_ALL_ADDONS_PACKAGE_IDS:
    process.env.REACT_APP_ALL_ADDONS_PACKAGE_IDS,
  REACT_APP_PRODUCTS_WITH_QUOTE_BREAKDOWN:
    process.env.REACT_APP_PRODUCTS_WITH_QUOTE_BREAKDOWN,
  REACT_APP_ENABLE_OPEN_QUOTES_RENEWALS:
    process.env.REACT_APP_ENABLE_OPEN_QUOTES_RENEWALS,
  REACT_APP_DISABLE_AUTH: process.env.REACT_APP_DISABLE_AUTH,
  REACT_APP_INSTALMENTS_ENABLED: process.env.REACT_APP_INSTALMENTS_ENABLED,
  REACT_APP_INSTALMENTS_PRODUCT_CODES:
    process.env.REACT_APP_INSTALMENTS_PRODUCT_CODES,
  REACT_APP_USAGE_IPT_PERCENTAGE: process.env.REACT_APP_USAGE_IPT_PERCENTAGE,
  REACT_APP_OPTIMIZE_QUOTE_FORM_EXPERIMENT_ID:
    process.env.REACT_APP_OPTIMIZE_QUOTE_FORM_EXPERIMENT_ID,
  REACT_APP_ZENDESK_LINK_NYR: process.env.REACT_APP_ZENDESK_LINK_NYR,
  REACT_APP_ZENDESK_LINK_DEFAULT: process.env.REACT_APP_ZENDESK_LINK_DEFAULT,
  REACT_APP_UBER_SERVICE_PATH: process.env.REACT_APP_UBER_SERVICE_PATH,
  REACT_APP_LOGIN_PARTNER_PATH: process.env.REACT_APP_LOGIN_PARTNER_PATH,
  REACT_APP_DOMAIN: process.env.REACT_APP_DOMAIN,
  REACT_APP_INSTALMENTS_BANNER_PRODUCT_CODES:
    process.env.REACT_APP_INSTALMENTS_BANNER_PRODUCT_CODES,
  REACT_APP_SHOW_TEST_BANNER: process.env.REACT_APP_SHOW_TEST_BANNER,
  REACT_APP_MINIMUM_QUOTE_START_DATE:
    process.env.REACT_APP_MINIMUM_QUOTE_START_DATE,

  REACT_APP_AUTO_RENEWAL_MINIMUM_OPT_IN_DAYS_BEFORE_EXPIRY:
    process.env.REACT_APP_AUTO_RENEWAL_MINIMUM_OPT_IN_DAYS_BEFORE_EXPIRY,

  REACT_APP_CUSTOMER_API_URI: process.env.REACT_APP_CUSTOMER_API_URI,
  REACT_APP_CUSTOMER_API_IS_ENABLED:
    process.env.REACT_APP_CUSTOMER_API_IS_ENABLED,
  REACT_APP_WALLET_BALANCE_ERROR: process.env.REACT_APP_WALLET_BALANCE_ERROR,
  REACT_APP_DISCONTINUED_PRODUCTS: process.env.REACT_APP_DISCONTINUED_PRODUCTS,
  REACT_APP_WALLET_URL_ENABLED: process.env.REACT_APP_WALLET_URL_ENABLED,
  REACT_APP_WALLET_URL: process.env.REACT_APP_WALLET_URL,
  REACT_APP_AMBASSADOR_URL: process.env.REACT_APP_AMBASSADOR_URL,
  REACT_APP_AMBASSADOR_ACTIVE: process.env.REACT_APP_AMBASSADOR_ACTIVE,
  REACT_APP_DATADOG_IS_ENABLED: process.env.REACT_APP_DATADOG_IS_ENABLED,
  REACT_APP_DATADOG_APPLICATION_ID:
    process.env.REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SITE: process.env.REACT_APP_DATADOG_SITE,
  REACT_APP_DATADOG_SERVICE: process.env.REACT_APP_DATADOG_SERVICE,
  REACT_APP_DATADOG_ENV: process.env.REACT_APP_DATADOG_ENV,
  REACT_APP_DATADOG_VERSION: process.env.REACT_APP_DATADOG_VERSION,
  REACT_APP_DATADOG_SESSION_SAMPLE_RATE:
    process.env.REACT_APP_DATADOG_SESSION_SAMPLE_RATE,
  REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE:
    process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE,
  REACT_APP_DATADOG_TRACK_RESOURCES:
    process.env.REACT_APP_DATADOG_TRACK_RESOURCES,
  REACT_APP_DATADOG_TRACK_LONG_TASKS:
    process.env.REACT_APP_DATADOG_TRACK_LONG_TASKS,
  REACT_APP_DATADOG_TRACK_USER_INTERACTIONS:
    process.env.REACT_APP_DATADOG_TRACK_USER_INTERACTIONS,
  REACT_APP_SELF_SERVE_CANCELLATIONS_ENABLED:
    process.env.REACT_APP_SELF_SERVE_CANCELLATIONS_ENABLED,
  REACT_APP_DISPLAY_PROGRESS_BAR: process.env.REACT_APP_DISPLAY_PROGRESS_BAR,
  REACT_APP_BLOCK_MTAS_WITHIN_RENEWAL_WINDOW:
    process.env.REACT_APP_BLOCK_MTAS_WITHIN_RENEWAL_WINDOW,
  REACT_APP_POLICY_CANCELLED_DASH_NOTIFICATION_DURATION_DAYS:
    process.env.REACT_APP_POLICY_CANCELLED_DASH_NOTIFICATION_DURATION_DAYS,
  REACT_APP_GET_USER_INVOICES: process.env.REACT_APP_GET_USER_INVOICES,
  REACT_APP_LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL,
  REACT_APP_WALLET_FEES_ENABLED: process.env.REACT_APP_WALLET_FEES_ENABLED,
  ...(window as any).env,
};

const { CONFIGURATION } = window;

function get<T>(name: string, defaultValue?: T) {
  return env[name] ?? defaultValue;
}

const configuration = {
  isProduction: get('NODE_ENV') === 'production',
  isDevelopment: get('NODE_ENV') === 'development',
  version: get('REACT_APP_BUILD_VERSION', 'local'),
  environment: get('REACT_APP_ENVIRONMENT', 'local'),
  maintenanceEnabled:
    get('MAINTENANCE_ENABLED', String(CONFIGURATION?.maintenanceEnabled)) ===
    'true',
  enableDevTools: get('REACT_APP_ENABLE_DEV_TOOLS', false) === 'true',
  region: get('REACT_APP_REGION', CONFIGURATION?.region ?? 'eu'),
  policy: {
    disableCreatePolicy:
      get('REACT_APP_DISABLE_CREATE_POLICY', 'true') === 'true',
    pollInterval: Number(get('REACT_APP_POLICY_POLL_INTERVAL', 2500)),
    pollTimeout: Number(get('REACT_APP_POLICY_POLL_TIMEOUT', 60000)),
  },
  verification: {
    enforce:
      get(
        'REACT_APP_ENFORCE_VERIFICATION',
        String(CONFIGURATION?.enforceVerification)
      ) === 'true',
    pollInterval: Number(get('REACT_APP_VERIFICATION_POLL_INTERVAL', 1500)),
    pollTimeout: Number(
      get('REACT_APP_VERIFICATION_POLL_TIMEOUT', 1000 * 60 * 10)
    ),
  },
  api: {
    base: get('REACT_APP_PUBLIC_API_BASE_URL'),
    document: get('REACT_APP_DOCUMENT_API_PATH'),
    billing: get('REACT_APP_BILLING_API_PATH'),
    flexLogin: get('REACT_APP_FLEX_API_LOGIN_PATH'),
    uberServicePath: get('REACT_APP_UBER_SERVICE_PATH'),
    loginPartnerPath: get('REACT_APP_LOGIN_PARTNER_PATH'),
    domain: get('REACT_APP_DOMAIN'),
  },
  auth0: {
    domain: get('REACT_APP_AUTH0_DOMAIN', CONFIGURATION?.auth0.domain),
    clientId: get('REACT_APP_AUTH0_CLIENT_ID', CONFIGURATION?.auth0.clientId),
    audience: get('REACT_APP_AUTH0_AUDIENCE', CONFIGURATION?.auth0.audience),
    redirectUri: `${window.location.origin}${get(
      'REACT_APP_AUTH0_REDIRECT_URI',
      CONFIGURATION?.auth0.redirectUri
    )}`,
    logoutUri: get(
      'REACT_APP_AUTH0_LOGOUT_URI',
      CONFIGURATION?.auth0.logoutUri
    ),
    connection: get(
      'REACT_APP_AUTH0_CONNECTION',
      CONFIGURATION?.auth0.connection
    ),
  },
  googleTagManager: {
    id: get('REACT_APP_GOOGLE_TAG_MANAGER_ID'),
    auth: get('REACT_APP_GOOGLE_TAG_MANAGER_AUTH'),
    preview: get('REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW'),
  },
  productId: {
    UKC: get('REACT_APP_UKC_ID'),
    UKC2: get('REACT_APP_UKC2_ID'),
    UKO2: get('REACT_APP_UKO2_ID'),
    UKO3: get('REACT_APP_UKO3_ID'),
    UKR2: get('REACT_APP_UKR2_ID'),
    UKR3: get('REACT_APP_UKR3_ID'),
    UKA: get('REACT_APP_UKA_ID'),
    UKA2: get('REACT_APP_UKA2_ID'),
    UKB: get('REACT_APP_UKB_ID'),
    UKB2: get('REACT_APP_UKB2_ID'),
    UKBL: get('REACT_APP_UKBL_ID'),
    UKBL2: get('REACT_APP_UKBL2_ID'),
    UKOG: get('REACT_APP_UKOG_ID'),
    UKOG2: get('REACT_APP_UKOG2_ID'),
  },
  wakamUkProductsStartTime: get('REACT_APP_WAKAM_UK_PRODUCT_START_TIME'),
  wakamProductRedirect:
    get('REACT_APP_WAKAM_PRODUCT_REDIRECT', 'false') === 'true',
  allowQuoteWhenActivePolicy: get(
    'REACT_APP_ALLOW_QUOTE_WHEN_ACTIVE_POLICY_FOR_USER_PRODUCT_CODES',
    ''
  ).split(','),
  enableForceQuote: get('REACT_APP_ENABLE_FORCE_QUOTE', 'false') === 'true',
  stripe: {
    stripeAccount: {
      NBV: get('REACT_APP_NBV_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      NBVO: get('REACT_APP_NBVO_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      UKB: get('REACT_APP_UKB_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      UKC: get('REACT_APP_UKC_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      UKR: get('REACT_APP_UKR_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      UKR2: get('REACT_APP_UKR2_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      UKO: get('REACT_APP_UKO_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      UKO2: get('REACT_APP_UKO2_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      NYR: get('REACT_APP_NYR_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      NYRS: get('REACT_APP_NYRS_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      AZR: get('REACT_APP_AZR_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      UKA: get('REACT_APP_UKA_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      UKA2: get('REACT_APP_UKA2_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      UKOG: get('REACT_APP_UKOG_STRIPE_ACCOUNT', 'STRIPE_SANDBOX'),
      UKC2: get('REACT_APP_UKC2_STRIPE_ACCOUNT', 'STRIPE_SANDBOX'),
      UKOG2: get('REACT_APP_UKOG2_STRIPE_ACCOUNT', 'STRIPE_SANDBOX'),
      UKR3: get('REACT_APP_UKR3_STRIPE_ACCOUNT', 'STRIPE_SANDBOX'),
      UKO3: get('REACT_APP_UKO3_STRIPE_ACCOUNT', 'STRIPE_SANDBOX'),
      UKB2: get('REACT_APP_UKB2_STRIPE_ACCOUNT', 'STRIPE_SANDBOX'),
      UKBL2: get('REACT_APP_UKBL2_STRIPE_ACCOUNT', 'STRIPE_SANDBOX'),
      'US-R-CO': get('REACT_APP_US_R_CO_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      'US-R-CA': get('REACT_APP_US_R_CA_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      'US-R-NY': get('REACT_APP_US_R_NY_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      'US-R-TX': get('REACT_APP_US_R_TX_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      'US-R-WA': get('REACT_APP_US_R_WA_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
      'US-R-GA': get('REACT_APP_US_R_GA_STRIPE_ACCOUNT', 'STRIPE_DEV0'),
    },
    key: {
      STRIPE_UKA: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_UKA'),
      STRIPE_UKC: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_UKC'),
      STRIPE_UKR: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_UKR'),
      STRIPE_UKR2: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_UKR2'),
      STRIPE_UKO: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_UKO'),
      STRIPE_UKO2: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_UKO2'),
      STRIPE_UKOG: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_UKOG'),
      STRIPE_SANDBOX: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_SANDBOX'),
      STRIPE_NBV: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_NBV'),
      STRIPE_NYR: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_NYR'),
      STRIPE_NYRS: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_NYRS'),
      STRIPE_AZR: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_AZR'),
      STRIPE_US_R_CA: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_CA'),
      STRIPE_US_R_CO: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_CO'),
      STRIPE_US_R_GA: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_GA'),
      STRIPE_US_R_NY: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_NY'),
      STRIPE_US_R_TX: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_TX'),
      STRIPE_US_R_WA: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_US_R_WA'),
      STRIPE_UK_WAKAM: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_UK_WAKAM'),
      STRIPE_DEV0: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV0'),
      STRIPE_TEST0: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST0'),
      STRIPE_PROD0: get('REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD0'),
    },
    externalPaymentTimeout: Number(
      get('REACT_APP_STRIPE_EXTERNAL_PAYMENT_TIMEOUT', 1200000)
    ),
    customerPortalLink: {
      NYR: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NYR'),
      NYRS: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NYRS'),
      AZR: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_AZR'),
      UKA: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKA'),
      UKC: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKC'),
      UKOG: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKOG'),
      UKOG2: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_STRIPE_SANDBOX'),
      UKR2: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKR2'),
      UKO2: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UKO2'),
      NBV: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NBV'),
      NBVO: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_NBVO'),
      'US-R-CA': get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_CA'),
      'US-R-CO': get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_CO'),
      'US-R-GA': get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_GA'),
      'US-R-NY': get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_NY'),
      'US-R-TX': get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_TX'),
      'US-R-WA': get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_US_R_WA'),
      UK_WAKAM: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_UK_WAKAM'),
      SANDBOX: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK_SANDBOX'),
      default: get('REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK'),
    },
  },
  loqate: {
    key: get('REACT_APP_LOQATE_API_KEY'),
  },
  locales: get('REACT_APP_LOCALES', 'en,nl').split(',') as ILocale[],
  defaultLocale: get('REACT_APP_DEFAULT_LOCALE', 'nl') as ILocale,
  documents: {
    url: get(
      'REACT_APP_DOCUMENTS_API_URL',
      `${CONFIGURATION?.documents.url}/${CONFIGURATION?.documents.version}`
    ),
    pollInterval: Number(get('REACT_APP_DOCUMENT_POLL_INTERVAL', 1000)),
    pollTimeout: Number(get('REACT_APP_DOCUMENT_POLL_TIMEOUT', 1000 * 3)),
  },
  quoteStartDateLeadTime: Number(
    get('REACT_APP_QUOTE_START_DATE_LEAD_TIME') ?? 10
  ),
  minimumQuoteStartDate: get('REACT_APP_MINIMUM_QUOTE_START_DATE'),
  autoRenewalOptInDaysBeforeExpiry:
    Number(get('REACT_APP_AUTO_RENEWAL_MINIMUM_OPT_IN_DAYS_BEFORE_EXPIRY')) ??
    1,

  sentry: {
    dsn: get('REACT_APP_SENTRY_DSN'),
  },
  marketingSiteURL: get(
    'REACT_APP_MARKETING_SITE_URL',
    CONFIGURATION?.marketingSiteURL
  ),
  fileHandlerMethod: get(
    'REACT_APP_FILE_HANDLER_METHOD',
    CONFIGURATION?.fileHandlerMethod
  ) as FileHandlerMethod,
  dropDownSearchMinLetterCount: Number(
    get('REACT_APP_SEARCH_MIN_LETTER_COUNT') ?? 3
  ),
  dropDownSearchMaxResultCount: Number(
    get('REACT_APP_SEARCH_MAX_RESULT_COUNT') ?? 10
  ),
  expireMTAQuoteCheckInterval: Number(
    get('REACT_APP_MTA_EXPIRE_CHECK_INTERVAL') ?? 1000
  ),
  minimumClaimsFreeYears: Number(
    get('REACT_APP_MINIMUM_CLAIMS_FREE_YEARS') ?? 4
  ),
  usageIptPercentage: Number(get('REACT_APP_USAGE_IPT_PERCENTAGE') ?? 12),
  disableSelfServe: get('REACT_APP_DISABLE_SELF_SERVE', 'true') === 'true',
  selfServeProductCodes: get('REACT_APP_SELF_SERVE_PRODUCT_CODES', 'UKR').split(
    ','
  ),
  guestQuoteProductCodes: get('REACT_APP_GUEST_QUOTE_PRODUCT_CODES', '').split(
    ','
  ),
  quoteFormProductCodes: get('REACT_APP_QUOTE_FORM_PRODUCT_CODES', '').split(
    ','
  ),
  enableAutoComplete: get('REACT_APP_ENABLE_AUTO_COMPLETE', 'false') === 'true',
  enableChatDelay: get('REACT_APP_ENABLE_CHAT_DELAY', 'false') === 'true',
  enableEvilAvatar: get('REACT_APP_ENABLE_EVIL_AVATAR', 'false') === 'true',
  enableStripeAccountFromConfig:
    get('REACT_APP_STRIPE_ACCOUNT_FROM_CONFIG', 'false') === 'true',
  enableAllPaymentOptionsFromBilling:
    get('REACT_APP_ENABLE_ALL_PAYMENT_OPTIONS_FROM_BILLING', 'false') ===
    'true',
  allAddonsPackageIds: get(
    'REACT_APP_ALL_ADDONS_PACKAGE_IDS',
    CONFIGURATION?.allAddonsPackageIds
  ).split(','),
  productsWithQuoteBreakdown: get(
    'REACT_APP_PRODUCTS_WITH_QUOTE_BREAKDOWN',
    CONFIGURATION?.productsWithQuoteBreakdown
  ).split(','),
  enableOpenQuotesRenewals:
    get('REACT_APP_ENABLE_OPEN_QUOTES_RENEWALS', 'true') === 'true',
  enableLineItems: get('REACT_APP_ENABLE_LINE_ITEMS', 'false') === 'true',
  enableDisplayLatestProducts:
    get('REACT_APP_ENABLE_DISPLAY_LATEST_PRODUCTS', 'false') === 'true',
  enableTranslationsFees:
    get('REACT_APP_ENABLE_TRANSLATIONS_FEES', 'false') === 'true',
  transientQueryParams: get('REACT_APP_TRANSIENT_QUERY_PARAMS', []).split(','),
  zendeskLinks: {
    NYR: get('REACT_APP_ZENDESK_LINK_NYR'),
    NYRS: get('REACT_APP_ZENDESK_LINK_NYR'),
    default: get('REACT_APP_ZENDESK_LINK_DEFAULT'),
  },

  disableAuth: get('REACT_APP_DISABLE_AUTH') === 'true',

  optimizeExperiment: {
    quoteForm: get('REACT_APP_OPTIMIZE_QUOTE_FORM_EXPERIMENT_ID'),
  },

  instalmentsBannerProducts: get(
    'REACT_APP_INSTALMENTS_BANNER_PRODUCT_CODES',
    ''
  ).split(','),

  showTestBanner: get('REACT_APP_SHOW_TEST_BANNER', 'false') === 'true',

  customerApi: {
    uri: get('REACT_APP_CUSTOMER_API_URI'),
    isEnabled: get('REACT_APP_CUSTOMER_API_IS_ENABLED', 'false') === 'true',
  },
  walletBalanceError: {
    isEnabled: get('REACT_APP_WALLET_BALANCE_ERROR', 'false') === 'true',
  },
  discontinuedProducts: get('REACT_APP_DISCONTINUED_PRODUCTS', '').split(','),
  isEnabledWalletUrl: get('REACT_APP_WALLET_URL_ENABLED', 'false') === 'true',
  walletUrl: get('REACT_APP_WALLET_URL', ''),
  ambassador: {
    url: get('REACT_APP_AMBASSADOR_URL'),
    active: get('REACT_APP_AMBASSADOR_ACTIVE', 'true') === 'true',
  },
  datadog: {
    isEnabled: get('REACT_APP_DATADOG_IS_ENABLED', 'false') === 'true',
    applicationId: get(
      'REACT_APP_DATADOG_APPLICATION_ID',
      'e3295cc4-9d9f-4210-a54f-22f72aad0585'
    ),
    clientToken: get(
      'REACT_APP_DATADOG_CLIENT_TOKEN',
      'pub9a3bec738e9351261e1fb7c07887df41'
    ),
    site: get('REACT_APP_DATADOG_SITE', 'datadoghq.eu'),
    service: get('REACT_APP_DATADOG_SERVICE', 'customer-web'),
    env: get('REACT_APP_DATADOG_ENV') ?? get('REACT_APP_ENVIRONMENT', 'local'),
    version:
      get('REACT_APP_DATADOG_VERSION') ??
      get('REACT_APP_BUILD_VERSION', 'local'),
    sessionSampleRate: Number.parseInt(
      get('REACT_APP_DATADOG_SESSION_SAMPLE_RATE', '100'),
      10
    ),
    sessionReplaySampleRate: Number.parseInt(
      get('REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE', '100'),
      10
    ),
    trackResources: get('REACT_APP_DATADOG_TRACK_RESOURCES', 'true') === 'true',
    trackLongTasks:
      get('REACT_APP_DATADOG_TRACK_LONG_TASKS', 'true') === 'true',
    trackUserInteractions:
      get('REACT_APP_DATADOG_TRACK_USER_INTERACTIONS', 'true') === 'true',
  },
  selfServeCancellationsEnabled:
    get('REACT_APP_SELF_SERVE_CANCELLATIONS_ENABLED', 'false') === 'true',
  releaseFlag: {
    displayProgressBar:
      get('REACT_APP_DISPLAY_PROGRESS_BAR', 'false') === 'true',
    enableGuestQuote: get('REACT_APP_ENABLE_GUEST_QUOTE', 'false') === 'true',
    enableLicenceInput:
      get('REACT_APP_ENABLE_LICENCE_INPUT', 'false') === 'true',
  },
  blockMtasWithinRenewalWindow:
    get('REACT_APP_BLOCK_MTAS_WITHIN_RENEWAL_WINDOW', 'false') === 'true',
  policyCancelledDashNotificationDurationDays: Number.parseInt(
    get('REACT_APP_POLICY_CANCELLED_DASH_NOTIFICATION_DURATION_DAYS', 1)
  ),
  getUserInvoices: get('REACT_APP_GET_USER_INVOICES', 'false') === 'true',
  logLevel: get('REACT_APP_LOG_LEVEL', 'info'),
  walletFeesEnabled: get('REACT_APP_WALLET_FEES_ENABLED', 'false') === 'true',
};

export type IConfiguration = typeof configuration;

// logger.info(JSON.stringify(configuration));

export class Configuration {
  static get(): IConfiguration {
    return configuration;
  }
}

export default configuration;
