import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { addSeconds, parseISO } from 'date-fns';
import merge from 'deepmerge';

import { ApplicationError, ErrorCodes, ProductError } from 'constants/errors';
import { parseKey } from 'utils/quoteUtils';
import EventManager from 'utils/EventManager';
import QuoteProvider from 'providers/QuoteProvider';
import { useLocale } from 'providers/LocaleProvider';
import ChatbotProvider from 'providers/ChatbotProvider';
import TrackingProvider, { QuoteType } from 'providers/TrackingProvider';
import CallApiHandlerProvider from 'providers/CallApiHandlerProvider';
import { useUser } from 'providers/UserProvider';

import {
  LoadedProductHistory,
  useGetAllPolicies,
  useGetPolicies,
  useGetQuotesForUser,
  useHistory,
  useProduct,
  useQuery,
  useStrings,
} from 'hooks';

import Chatbot from 'components/Chatbot/Chatbot';
import Loader from 'components/Loader/Loader';
import Page from 'components/Page/Page';
import Redirect from 'components/Router/Redirect';
import Button from '../../../../components/Buttons/Button';
import { useModal } from '../../../../providers/ModalProvider';

import './QuotePage.scss';
import { adjustProductIdBasedOnDate } from '../../../../utils/productRedirectUtils';
import configuration from 'configuration';
import { shouldShowStartQuote } from '../../../../utils/shouldShowStartQuoteUtils';

function QuotePage() {
  const { push } = useHistory();
  const { display, dismiss } = useModal();
  const { get } = useStrings();
  const { id: productId } = useParams<{ id: string }>();

  // TODO: remove after 1st of October Jira ticket F2-1477
  const id = configuration.wakamProductRedirect
    ? adjustProductIdBasedOnDate(productId)
    : productId;

  const { renewalOf, force, newOpenQuote } = useQuery<{
    newOpenQuote?: string;
    renewalOf?: string;
    force?: boolean;
  }>();

  const renewalKey = useMemo(() => parseKey(renewalOf), [renewalOf]);

  const { lang: locale } = useLocale();
  const user = useUser();

  const quoteType = renewalOf ? QuoteType.RENEWAL : QuoteType.NEW_BUSINESS;

  const {
    loading: loadingProduct,
    productHistory,
    error: productError,
  } = useProduct(id, locale, false);
  const product = productHistory.current;

  // Purposefully not filtering by product id. There might be instances where we
  // need the policy from an expired product to renew (e.g. UKO -> UKO2)
  const {
    loading: loadingPolicies,
    policies: activePendingPolicies,
    error: policiesError,
  } = useGetPolicies({ statuses: ['ACTIVE', 'PENDING'] });

  const { quotes, loading: loadingQuotes } = useGetQuotesForUser();

  const { allPolicies, loading: loadingAllVersions } = useGetAllPolicies();

  const activeNotTakenUpQuotes = quotes?.filter((quote) => {
    if (allPolicies) {
      for (const pol of allPolicies) {
        if (pol.quote && pol.quote.id === quote.id) {
          return false;
        }
      }
    }
    return true;
  });

  const activeNonMtaOrRenewalQuotes = activeNotTakenUpQuotes?.filter(
    (quote) => !quote.mtaOf && !quote.renewalOf
  );

  const hasOpenQuotes = useMemo(() => {
    if (force || renewalOf) {
      return false;
    }
    return (
      activeNonMtaOrRenewalQuotes &&
      activeNonMtaOrRenewalQuotes.some(
        (quote) => quote.product.id === product?.id
      )
    );
  }, [activeNonMtaOrRenewalQuotes, force, renewalOf, product?.id]);

  // Find a policy that matches the renewal query param.
  const policyReqForRenewal = useMemo(() => {
    if (!activePendingPolicies || !renewalKey) {
      return undefined;
    }

    return activePendingPolicies.find(
      ({ id, version }) =>
        id === renewalKey?.id && version === renewalKey?.version
    );
  }, [activePendingPolicies, renewalKey]);

  const canQuote = useMemo(() => {
    if (force && configuration.enableForceQuote) {
      return true;
    }

    // Override hasOpenQuotes if user selected 'Get Quote' from ProductPage (no policies)
    // Otherwise, this current logic disallows a new quote, if quotes exist for an auth0 user.
    if (hasOpenQuotes && !newOpenQuote) {
      return false;
    }

    const showStartQuote = shouldShowStartQuote(
      activePendingPolicies,
      product,
      configuration.allowQuoteWhenActivePolicy,
      quoteType,
      policyReqForRenewal
    );

    if (showStartQuote) {
      return true;
    }

    return false;
  }, [
    force,
    quoteType,
    activePendingPolicies,
    policyReqForRenewal,
    hasOpenQuotes,
    newOpenQuote,
    product,
  ]);

  const loading =
    loadingProduct || loadingPolicies || loadingQuotes || loadingAllVersions;

  const initialValue = useMemo(() => {
    if (!policyReqForRenewal) {
      return {};
    }

    const endDate = addSeconds(parseISO(policyReqForRenewal.activeTo), 1);

    return merge(policyReqForRenewal?.datasheet, {
      policy: {
        startDateTime: endDate.toISOString(),
      },
    });
  }, [policyReqForRenewal]);

  function showLeavePageConfirmationModal() {
    function onCancel() {
      dismiss();
    }

    function leave() {
      push(`/product/${id}`);
      dismiss();
    }

    display('leave_page_confirmation', {
      title: get('pages.product.mta.modal.leave_page.title'),
      message: get('pages.product.mta.modal.leave_page.message'),
      onCancel,
      actions: (
        <>
          <Button variant="secondary" onClick={onCancel} testId="leave-cancel">
            {get('pages.product.mta.modal.leave_page.cancel')}
          </Button>
          <Button variant="primary" onClick={leave} testId="leave-confirm">
            {get('pages.product.mta.modal.leave_page.leave')}
          </Button>
        </>
      ),
    });
  }

  const error = policiesError || productError;

  if (error) {
    throw new ApplicationError(error.message, ErrorCodes.SERVER_ERROR);
  }

  if (loading) {
    return <Loader />;
  }

  if (!product) {
    throw new ProductError(id, ErrorCodes.PRODUCT_NOT_FOUND);
  }

  if (!canQuote) {
    return <Redirect to={`/product/${id}`} />;
  }

  const shouldShowForm: boolean =
    configuration.quoteFormProductCodes.includes(product.code) &&
    quoteType === QuoteType.NEW_BUSINESS;

  if (shouldShowForm) {
    return <Redirect to={`/product/${id}/quote/form`} preserveQuery />;
  }

  EventManager.track({
    event: 'QuoteStarted',
    productId: product.id,
    quoteType,
  });

  EventManager.track({
    event: 'QuoteFlowStart',
    productId: product.id,
    label: 'chat',
  });

  return (
    <TrackingProvider defaultArguments={{ productId: id, quoteType }}>
      <ChatbotProvider>
        <CallApiHandlerProvider>
          <QuoteProvider
            user={user}
            productHistory={productHistory as LoadedProductHistory}
            renewalOf={renewalKey}
            initialValue={initialValue}
            quoteType={quoteType}
          >
            <Chatbot
              onClose={
                (activeNonMtaOrRenewalQuotes &&
                  activeNonMtaOrRenewalQuotes.length > 0) ||
                renewalOf
                  ? showLeavePageConfirmationModal
                  : undefined
              }
            />
          </QuoteProvider>
        </CallApiHandlerProvider>
      </ChatbotProvider>
    </TrackingProvider>
  );
}

function withPage(Component: React.ElementType) {
  return function WrappedPage() {
    return (
      <Page
        name="QuotePage"
        slug="Quote"
        padded={false}
        onRestart={() =>
          setTimeout(function () {
            window.location.reload();
          })
        }
      >
        <Component />
      </Page>
    );
  };
}

export default withPage(QuotePage);
