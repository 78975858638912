import { QuoteType } from 'providers/TrackingProvider';
import {
  IPolicyStatus,
  IProductDetailsFragment,
} from 'shared/graphql/api/types';

interface ActivePendingPolicy {
  id: string;
  version?: string | null;
  policyNumber: string;
  status: IPolicyStatus;
  canRenew: boolean;
  datasheet: any;
  activeTo: string;
  product: {
    id: string;
    code: string;
  };
}
interface PolicyReqForRenewalInterface {
  id: string;
  version?: string | null | undefined;
  policyNumber: string;
  status: IPolicyStatus;
  canRenew: boolean;
  datasheet: any;
  activeTo: string;
  product: {
    id: string;
    code: string;
  };
}

export type PolicyReqForRenewal = PolicyReqForRenewalInterface | undefined;

export type ActivePendingPolicies = ActivePendingPolicy[] | undefined;

export function shouldShowStartQuote(
  activePendingPolicies: ActivePendingPolicies,
  product: IProductDetailsFragment | undefined,
  configurationProductCode: string[],
  quoteType: QuoteType,
  policyReqForRenewal?: PolicyReqForRenewal
): boolean {
  const allowQuoteWhenActivePolicy = configurationProductCode.includes(
    product?.code ?? ''
  );
  if (allowQuoteWhenActivePolicy) {
    return true;
  }

  if (quoteType === QuoteType.NEW_BUSINESS) {
    const activePendingPolicyProductCode =
      activePendingPolicies &&
      activePendingPolicies.map((product) =>
        product.product.code.replace(/[^a-zA-Z]/g, '')
      );

    const productCode = product?.code.replace(/[^a-zA-Z]/g, '') ?? '';

    return !activePendingPolicyProductCode?.includes(productCode);
  }

  if (quoteType === QuoteType.RENEWAL) {
    // TODO: Leaving this here as logic may need to come back in when BE
    //  adds fix to expire all open quotes when user buys a policy.
    //  Currently users are able to buy multiple policies, so it is possible
    //  that they may have >1 policies due for renewal.
    // const hasNewerPolicy =
    //   policies &&
    //   policy &&
    //   policies.some(
    //     (p) =>
    //       new Date(p.activeTo) > new Date(policy!.activeTo) &&
    //       ['ACTIVE', 'PENDING'].includes(p.status)
    //   );
    return policyReqForRenewal?.canRenew || false;
  }
  return true;
}
