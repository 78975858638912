import Modal from 'components/Modal/Modal';
import { Formik } from 'formik';
import { useStrings } from 'hooks';
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { usePreQuote } from 'providers/QuoteProvider';
import { buildDriverLicence } from './Licence.utils';
import LicenceForm from './LicenceForm';

import './LicenceModal.scss';

interface Props {
  active: boolean;
  onClose(values?: {
    licence: string;
    firstName: string;
    lastName: string;
  }): void;
  driverNumber: number;
}

export interface Licence {
  firstName: string;
  lastName: string;
  endLicence: string;
  gender: 'M' | 'F' | '';
  dob: string;
}

const LICENCE_INITIAL_VALUES: Pick<Licence, 'endLicence' | 'gender' | 'dob'> = {
  endLicence: '',
  gender: '',
  dob: '',
};

export const LicenceSchema = yup.object().shape({
  gender: yup
    .string()
    .required('Please provide a value')
    .matches(/^[FM]$/, 'Must be F or M'),
  dob: yup.string().required('Please provide a value'),
  endLicence: yup
    .string()
    .required('Please provide a value')
    .length(4, 'Must be exactly 4 characters'),
});

export function LicenceModal({ active, onClose, driverNumber }: Props) {
  const { get } = useStrings();
  const [builtLicence, setBuiltLicence] = useState('');
  const { $ } = usePreQuote();

  const { firstName, lastName } = useMemo(() => {
    return (
      $.datasheet?.drivers[driverNumber] || { firstName: '', lastName: '' }
    );
  }, [$, driverNumber]);

  const onSubmit = (values: Licence) => {
    const licenceValues = {
      licence: builtLicence + values.endLicence,
      firstName: values.firstName,
      lastName: values.lastName,
    };
    onClose(licenceValues);
  };

  const generateLicence = (values: Licence) => {
    const preBuilt = buildDriverLicence({
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob,
      isFemale: values.gender === 'F',
    });
    setBuiltLicence(preBuilt);
  };

  if (!active) {
    return null;
  }

  return (
    <Modal
      title={get('pages.quote.licence.title')}
      className="LicenceModal"
      onClose={onClose}
      data-testid="licence-modal"
    >
      <Formik<Licence>
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={LicenceSchema}
        initialValues={{ ...LICENCE_INITIAL_VALUES, firstName, lastName }}
      >
        {(props) => (
          <LicenceForm
            {...props}
            generateLicence={generateLicence}
            builtLicence={builtLicence}
          />
        )}
      </Formik>
    </Modal>
  );
}

export default LicenceModal;
