import { Full } from 'components/Layout/Layout';
import Page from 'components/Page/Page';
import { useAuth } from 'providers/AuthProvider';
import { useParams } from 'react-router-dom';
import Button from 'components/Buttons/Button';
import { useEffect, useState } from 'react';
import { useMigrateUserQuotesMutation } from 'shared/graphql/api/types';
import { renderToString } from 'components/String/String';
import Loader from 'components/Loader/Loader';
import { useHistory } from 'hooks';

function QuoteGuestMigrate() {
  const [error, setError] = useState<string>('');
  const { user } = useAuth();
  const { quoteId, productId, packageId } = useParams<{
    quoteId: string;
    productId: string;
    packageId: string;
  }>();
  const { push } = useHistory();
  const [migrate, { loading }] = useMigrateUserQuotesMutation();

  useEffect(() => {
    if (user) {
      updateQuotesForUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId, user]);

  async function updateQuotesForUser() {
    setError('');

    try {
      const { data, errors } = await migrate();

      if (errors && errors.length > 0) {
        setError(
          `Please retry or contact support with this references:\n- quoteId: ${quoteId}\n- userId: ${user?.sub}\n`
        );
      }
      if (data?.migrate.includes(quoteId)) {
        return push(
          `/product/${productId}/quote/${quoteId}/package/${packageId}/overview`
        );
      } else {
        setError(
          `Please retry or contact support with this references:\n- quoteId: ${quoteId}\n- userId: ${user?.sub}\n`
        );
      }
    } catch (e) {
      setError(
        `Please retry or contact support with this references:\n- quoteId: ${quoteId}\n- userId: ${user?.sub}\n`
      );
    }
  }

  return (
    <div
      data-testid="quote-guest-migrate"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {loading && <Loader />}
      {!loading && error && (
        <div
          style={{
            background: 'white',
            padding: '2rem',
            borderRadius: '0.75rem',
            boxShadow: '1px 3px 1px #00000017',
            border: '1px solid var(--color-border-light-main)',
          }}
        >
          <h3>{error ? 'Something went wrong' : 'Preparing your quotes'}</h3>

          <div>
            <div>
              {error.split('\n').map((message: string) => (
                <div style={{ marginBottom: '16px' }}>
                  {renderToString(message)}
                </div>
              ))}
            </div>
            <div style={{ marginTop: ' 2rem', display: 'flex' }}>
              <Button
                variant="secondary"
                onClick={updateQuotesForUser}
                testId="update-user-quotes"
              >
                Retry
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
const withPage = (Component: React.ElementType) => () => {
  return (
    <Page name="QuoteOverviewPage" slug="Quote Overview" padded={false}>
      <Full className="QuoteOverview">
        <Component />
      </Full>
    </Page>
  );
};

export default withPage(QuoteGuestMigrate);
