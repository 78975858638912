import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { addSeconds, parseISO } from 'date-fns';
import merge from 'deepmerge';

import { ApplicationError, ErrorCodes, ProductError } from 'constants/errors';
import { parseKey } from 'utils/quoteUtils';
import EventManager from 'utils/EventManager';
import QuoteProvider from 'providers/QuoteProvider';
import { useLocale } from 'providers/LocaleProvider';
import { QuoteType } from 'providers/TrackingProvider';
import CallApiHandlerProvider from 'providers/CallApiHandlerProvider';
import { useUser } from 'providers/UserProvider';

import configuration from 'configuration';
import {
  LoadedProductHistory,
  useGetAllPolicies,
  useGetPolicies,
  useGetQuotesForUser,
  useProduct,
  useQuery,
} from 'hooks';

import Loader from 'components/Loader/Loader';
import Page from 'components/Page/Page';
import Redirect from 'components/Router/Redirect';
import QuoteFormSteps from './QuoteFormSteps';
import { ThemeProvider, createTheme } from '@material-ui/core';

import './QuoteForm.scss';
import { adjustProductIdBasedOnDate } from '../../../../../utils/productRedirectUtils';
import { shouldShowStartQuote } from '../../../../../utils/shouldShowStartQuoteUtils';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0e203e',
    },
  },
});

export function QuotePageForm() {
  const { id: productId } = useParams<{ id: string }>();

  // TODO: remove after 1st of October Jira ticket F2-1477
  const id = configuration.wakamProductRedirect
    ? adjustProductIdBasedOnDate(productId)
    : productId;

  const { renewalOf, force, newOpenQuote } = useQuery<{
    newOpenQuote?: boolean;
    renewalOf?: string;
    force?: boolean;
  }>();

  const renewalKey = useMemo(() => parseKey(renewalOf), [renewalOf]);

  const { lang: locale } = useLocale();
  const user = useUser();

  const quoteType = renewalOf ? QuoteType.RENEWAL : QuoteType.NEW_BUSINESS;

  const {
    loading: loadingProduct,
    productHistory,
    error: productError,
  } = useProduct(id, locale, false);
  const product = productHistory.current;
  const {
    loading: loadingPolicies,
    policies,
    error: policiesError,
  } = useGetPolicies({ statuses: ['ACTIVE', 'PENDING'] });

  const { quotes, loading: loadingQuotes } = useGetQuotesForUser();
  const { allPolicies, loading: loadingAllVersions } = useGetAllPolicies();

  const activeNotTakenUpQuotes = quotes?.filter((quote) => {
    if (allPolicies) {
      for (const pol of allPolicies) {
        if (pol.quote && pol.quote.id === quote.id) {
          return false;
        }
      }
    }
    return true;
  });

  const activeNonMtaOrRenewalQuotes = activeNotTakenUpQuotes?.filter(
    (quote) => !quote.mtaOf && !quote.renewalOf
  );

  const hasOpenQuotes = useMemo(() => {
    if (force || renewalOf) {
      return false;
    }
    return (
      activeNonMtaOrRenewalQuotes && activeNonMtaOrRenewalQuotes.length > 0
    );
  }, [activeNonMtaOrRenewalQuotes, force, renewalOf]);

  const policy = useMemo(() => {
    if (!policies) {
      return undefined;
    }

    return policies.find(
      ({ id, version }) =>
        id === renewalKey?.id && version === renewalKey?.version
    );
  }, [policies, renewalKey]);

  const canQuote = useMemo(() => {
    if (force && configuration.enableForceQuote) {
      return true;
    }

    if (hasOpenQuotes && !newOpenQuote) {
      return false;
    }

    const showStartQuote = shouldShowStartQuote(
      policies,
      product,
      configuration.allowQuoteWhenActivePolicy,
      quoteType,
      policy
    );

    if (showStartQuote) {
      return true;
    }

    return false;
  }, [
    product,
    force,
    quoteType,
    policies,
    policy,
    hasOpenQuotes,
    newOpenQuote,
  ]);

  const loading =
    loadingProduct || loadingPolicies || loadingQuotes || loadingAllVersions;

  const initialValue = useMemo(() => {
    if (!policy) {
      return {};
    }

    const endDate = addSeconds(parseISO(policy.activeTo), 1);

    return merge(policy?.datasheet, {
      policy: {
        startDateTime: endDate.toISOString(),
      },
    });
  }, [policy]);

  if (loading) {
    return <Loader />;
  }

  const error = policiesError || productError;

  if (error) {
    throw new ApplicationError(error.message, ErrorCodes.SERVER_ERROR);
  }

  if (!product) {
    throw new ProductError(id, ErrorCodes.PRODUCT_NOT_FOUND);
  }

  if (!canQuote) {
    return <Redirect to={`/product/${id}`} />;
  }

  if (product && !configuration.quoteFormProductCodes.includes(product.code)) {
    return <Redirect to={`/product/${id}/quote`} preserveQuery />;
  }

  if (renewalOf) {
    return (
      <Redirect
        to={`/product/${id}/quote?renewalOf=${renewalOf}`}
        preserveQuery
      />
    );
  }

  EventManager.track({
    event: 'QuoteStarted',
    productId: product.id,
    quoteType,
  });

  EventManager.track({
    event: 'QuoteFlowStart',
    productId: product.id,
    label: 'form',
  });

  return (
    <CallApiHandlerProvider>
      <ThemeProvider theme={theme}>
        <QuoteProvider
          user={user}
          productHistory={productHistory as LoadedProductHistory}
          initialValue={initialValue}
          quoteType={quoteType}
        >
          <QuoteFormSteps />
        </QuoteProvider>
      </ThemeProvider>
    </CallApiHandlerProvider>
  );
}

function withPage(Component: React.ElementType) {
  return function WrappedPage() {
    return (
      <Page
        name="QuotePageForm"
        slug="Quote"
        padded={false}
        onRestart={() =>
          setTimeout(function () {
            window.location.reload();
          })
        }
      >
        <Component />
      </Page>
    );
  };
}

export default withPage(QuotePageForm);
