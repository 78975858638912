import React from 'react';
import './OpenQuote.scss';
import './OpenQuoteGroup.scss';
import Button from '../Buttons/Button';
import String from '../String/String';
import { AnnualIcon, ThirtyDaysIcon } from 'components/Icons';
import { useHistory } from 'hooks';
import { useParams } from 'react-router-dom';
import OpenQuotePlanPrices, { InstalmentPrices } from './OpenQuotePlanPrices';
import InstalmentsBanner from 'components/InstalmentsBanner/InstalmentsBanner';
import { IQuoteStatus } from 'shared/graphql/api/types';
import { QuoteStatusEnum } from '@inshur/apis/quote';

interface Props {
  quoteId: string;
  quoteNumber: string;
  duration: QuoteDuration;
  renewal: boolean;
  showInstalments?: boolean;
  showFinance?: boolean;
  price?: string;
  prices?: InstalmentPrices;
  quoteStatus: IQuoteStatus;
  currency: string;
}

export enum QuoteDuration {
  DAYS_365 = 'DAYS_365',
  DAYS_30 = 'DAYS_30',
}

export const durationIcons = {
  [QuoteDuration.DAYS_30]: <ThirtyDaysIcon />,
  [QuoteDuration.DAYS_365]: <AnnualIcon />,
};

function OpenQuoteDuration({
  quoteId,
  quoteNumber,
  duration,
  renewal,
  showInstalments,
  showFinance,
  price,
  prices,
  quoteStatus,
  currency,
}: Props) {
  const { push } = useHistory();
  const { id: productId } = useParams<{ id: string }>();

  const viewQuote = () => {
    const quoteOptionsUrl = `/product/${productId}/quote/${quoteId}/options`;
    push(quoteOptionsUrl);
  };

  const renderPackagePrices = (): JSX.Element => {
    if (prices && prices.inFullAmount && prices.instalmentAmount) {
      if (showInstalments) {
        return (
          <OpenQuotePlanPrices
            prices={prices}
            planType="instalments"
            currency={currency}
          />
        );
      }

      if (showFinance) {
        return (
          <OpenQuotePlanPrices
            prices={prices}
            planType="finance"
            currency={currency}
          />
        );
      }
    }

    return (
      <p
        data-testid="duration-price"
        className="OpenQuote-section OpenQuote-price"
      >
        {price}
      </p>
    );
  };

  return (
    <div data-testid="open-quote-duration" className="OpenQuoteGroup-duration">
      {renewal && (
        <p className="OpenQuote-section OpenQuote-renewal-of">Renewal Quote</p>
      )}

      <div className="OpenQuote-wrapper">
        {durationIcons[duration]}
        <p className="OpenQuote-section OpenQuoteGroup-day-quote">
          <String id={`pages.product.open_quote.policy_duration.${duration}`} />
        </p>
        <p
          data-testid="duration-quote-ref-title"
          className="OpenQuote-section OpenQuoteGroup-ref-title"
        >
          <String id="pages.open_quote.reference" />
        </p>
        <p
          data-testid="duration-quote-ref"
          className="OpenQuote-section OpenQuoteGroup-ref"
        >
          {quoteNumber}
        </p>

        {quoteStatus === QuoteStatusEnum.OK && renderPackagePrices()}

        <InstalmentsBanner
          style={{ marginBottom: '1.5rem' }}
          visible={duration === QuoteDuration.DAYS_365}
          isRenewal={renewal}
          productId={productId}
        />

        <Button
          testId="continue-quote"
          className="OpenQuote-continue"
          variant="primary"
          onClick={viewQuote}
        >
          <String id={`pages.product.open_quote.view.${duration}`} />
        </Button>
      </div>
    </div>
  );
}

export default OpenQuoteDuration;
