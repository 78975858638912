import { PropsWithChildren, useEffect } from 'react';

import Button from 'components/Buttons/Button';
import Modal from '../Modal';
import { useCreatePolicy, useStrings } from '../../../hooks';
import String from '../../String/String';
import './CancelPolicyModal.scss';
import ContactUsButton from 'components/Buttons/ContactUs/ContactUs';
import { useCreateQuote } from 'hooks/useCreateQuote';
import {
  IFlexWalletPaymentPlan,
  IPolicyDetailFragment,
} from 'shared/graphql/api/types';
import { getZendeskLink } from 'utils/policyUtils';
import Link from 'components/Router/Link';
import EventManager from 'utils/EventManager';
import configuration from 'configuration';
import { hasWalletServiceFee } from 'utils/quoteUtils';
interface Props {
  policy: IPolicyDetailFragment;
  onCancel(): void;
  onSuccess(): void;
}

function CancelPolicyModal({
  policy,
  onCancel,
  onSuccess,
}: PropsWithChildren<Props>) {
  const { package: policyPackage, product, policyNumber } = policy;
  const productCode = product.code;

  const {
    create: createQuote,
    loading: quoteLoading,
    error: createQuoteError,
  } = useCreateQuote();
  const {
    create: createPolicy,
    loading: policyLoading,
    policy: cancellationPolicy,
    error: createPolicyError,
  } = useCreatePolicy();

  const { get } = useStrings();

  async function onConfirmCancelPolicyButtonClicked() {
    const { data } = await createQuote({
      product: {
        id: policy.product.id,
        version: policy.product.version,
      },
      datasheet: policy.datasheet,
      cancellationOf: {
        id: policy.id,
        version: policy.version,
      },
    });

    await createPolicy({
      packageId: policyPackage.id,
      quoteId: data!.quote.id,
    });
  }

  useEffect(() => {
    if (cancellationPolicy) {
      EventManager.track({
        policyNumber,
        productId: product.id,
        event: 'PolicyCancelled',
      });
    }
  }, [cancellationPolicy, policyNumber, product]);

  if (cancellationPolicy) {
    onSuccess();
    return (
      <Modal
        title={get('pages.product.dashboard.modal.cancel_policy.title')}
        className="CancelPolicyModal"
        data-testid="cancel-policy-modal"
        onClose={onCancel}
        actionsFlow="column"
        actions={
          <Button variant="secondary" onClick={onCancel} testId="cancel-button">
            <String id="commons.ok" />
          </Button>
        }
      >
        <div>
          <String id="pages.product.dashboard.modal.cancel_policy.policy_cancelled" />
        </div>
      </Modal>
    );
  }

  if (createQuoteError || createPolicyError) {
    return (
      <Modal
        title={get('pages.product.dashboard.modal.cancel_policy.title')}
        className="CancelPolicyModal"
        data-testid="cancel-policy-modal"
        onClose={onCancel}
        actionsFlow="column"
        actions={
          <div className="CancelPolicyModal-contact-button">
            <ContactUsButton />{' '}
          </div>
        }
      >
        <div>
          <String id="pages.error.message" />
        </div>
      </Modal>
    );
  }

  const isAmazonFlex = productCode.startsWith('UKA');

  return (
    <Modal
      title={get('pages.product.dashboard.modal.cancel_policy.title')}
      className="CancelPolicyModal"
      data-testid="cancel-policy-modal"
      onClose={onCancel}
      loading={quoteLoading || policyLoading}
      loadingMessage={get(
        'pages.product.dashboard.modal.cancel_policy.cancellation_loading'
      )}
      actionsFlow="column"
      actions={
        <>
          {!policyLoading && !quoteLoading && (
            <>
              <Button
                variant="secondary"
                onClick={onCancel}
                testId="cancel-button"
              >
                <String id="commons.no" />
              </Button>
              <Button
                variant="tertiary"
                onClick={() => onConfirmCancelPolicyButtonClicked()}
                testId="confirm-button"
              >
                <String id="pages.product.dashboard.modal.cancel_policy.confirm" />
              </Button>
            </>
          )}
        </>
      }
    >
      <>
        <div>
          <String id="pages.product.dashboard.modal.cancel_policy.modal_message" />
          <p className="CancelPolicyModal-bold-message">
            <String id="pages.product.dashboard.modal.cancel_policy.information" />
          </p>
        </div>
        {isAmazonFlex && (
          <div>
            <String id="pages.product.dashboard.modal.cancel_policy.uka.refund_information" />
          </div>
        )}
        {configuration.walletFeesEnabled &&
          isAmazonFlex &&
          hasWalletServiceFee(
            policy.package.paymentPlan as IFlexWalletPaymentPlan
          ) && (
            <div>
              <String id="pages.product.dashboard.modal.cancel_policy.uka.wallet_fee_information" />
            </div>
          )}

        <div>
          {get('pages.product.dashboard.modal.cancel_policy.more_information', {
            link: (
              <Link
                to="#"
                data-testid="faq-link"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = getZendeskLink(policy);
                }}
              >
                <String id="pages.product.dashboard.modal.cancel_policy.faq" />
              </Link>
            ),
          })}
        </div>
      </>
    </Modal>
  );
}

export default CancelPolicyModal;
